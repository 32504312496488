import React, { useState, useEffect } from "react";
import "../../assets/css/Blogs.css";
import { blogs } from "../../assets/Images/ImagesLink";
import BlogCard from "./BlogCard";
import BlogTwo from "./BlogTwo";
import Select from "react-select";
import BlogsNav from "./BlogsNav";

const Blogs = () => {

  const [tab, setTab] = useState("");

  const options = [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Thane", label: "Thane" },
    { value: "Pune", label: "Pune" },
  ];

  return (
    <>

      <div className="blogContainer">
        <div className="blogTab">
          <BlogsNav setTab={setTab} tab={tab} />
        </div>
        <div className="blogCard">
          <BlogCard tab={tab} />
        </div>
      </div>
    </>
    // <div className="blogMain">
    //   <div className=" row blogs main-container  mt-5">
    //     <div className="col-md-12 blogs-column">
    //       <div className="blogs-tab ">
    //         <BlogsNav setTab={setTab} tab={tab} />
    //       </div>
    //       <BlogCard tab={tab} />
    //     </div>
    //   </div>
    //   <div className="row">
    //     <div className="col-md-12">
    //       <BlogCard />
    //     </div>
    //   </div>
    // </div>
  );
};

export default Blogs;
