import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { people } from "../../assets/Images/ImagesLink";
import EmojiPicker from "emoji-picker-react";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import AddReactionSharpIcon from '@mui/icons-material/AddReactionSharp';
const PostComment = ({
  postId,
  item,
  AllPostList,
  postList,
  setPage,
  setPostList,
  page,
  setTotalPages,
  totalPages,
  UserImage,
  updateCommentCount
}) => {
  const sessionId = localStorage.getItem("sessionId");
  //************************Post Comment  ************************//
  const [content, setContent] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  console.log('postListhgsdjhgds', postList);

  const postData = async () => {
    const postdatadetails = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
      comment: content,
    };

    try {
      const res = await axios.post("PostAction/CommentPost", postdatadetails);
      setContent("");
      setToastMessage(res.data.message);
      setShowToast(true);
      updateCommentCount(postId, 1)
      const responseData = res.data.responseData;
      console.log('responseData', responseData);
      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
      const updatedPost = postDetailsResponse.data.responseData;
      // console.log('updatedPost', updatedPost);

      // Update the existing post in the list
      setPostList((prev) =>
        prev.map((post) =>
          post.postId === updatedPost.postId ? updatedPost : post
        )
      );
      // setPostList((prevList) =>
      //   prevList.map((item) =>
      //     item.postId === postId
      //       ? {
      //         ...item,
      //         postComments: [
      //           {
      //             likeCount: 0,
      //             pstCmntId: responseData.pstCmntId,
      //             comment: responseData.comment,
      //             useruid: responseData.useruid,
      //             userName: responseData.userName,
      //             isOwned: true,
      //             userAvatar: profilPic,
      //             creationTime: responseData.creationTime,
      //             postCommentsReplies: [],
      //           },
      //           ...item.postComments,
      //         ],
      //       }
      //       : item
      //   )
      // );
    } catch (error) {
      setErrorToastMessage(error.message);
      setShowErrorToast(true)
      //   console.log("ERRR",error.message);

    }
  };

  // console.log("postList", postList);

  const handleKeyPress = () => {
    postData();
  };
  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const handleEmojiClose = () => {
    setShowEmojiPicker(false);
  };
  //************************Post Comment  ************************//
  console.log('postList', postList);
  const profilPic = localStorage.getItem("profilPic");
  return (
    <>
      {/* {postList.map((item, index) => {
        return ( */}
      <div className="commentArea" >
        <div>
          <img
            src={profilPic}
            width={35}
            height={35}
            alt=""
            style={{ borderRadius: "50px" }}
          />
        </div>
        <div className="commentBox">
          <input
            type="text"
            placeholder="Write your comment…"
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleKeyPress();
              }
            }}
          />
          <AddReactionSharpIcon
            className="material-symbols-sharp me-2"
            onClick={handleEmojiClick}
            style={{ cursor: "pointer" }}
          />
          <SendSharpIcon
            className="material-symbols-sharp"
            onClick={() => postData()}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>


      {showEmojiPicker && (
        <div>
          <EmojiPicker
            onEmojiClick={(e, emojiObject) => {
              // { console.log(e.emoji, emojiObject) }
              setContent(content + e.emoji);
              setShowEmojiPicker(false);
            }}
          />
          <div
            className="emoji-close"
            onClick={handleEmojiClose}
            style={{ cursor: "pointer" }}
          >
            <CloseSharpIcon className="material-symbols-sharp"
            />
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />
      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default PostComment;
