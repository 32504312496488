import React, { useEffect, useRef, useState } from "react";
// import {
//   Dropdown,
//   DropdownButton,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
//   Modal,
// } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { NewsFeed } from "../../assets/Images/ImagesLink";
import { Link } from "react-router-dom";
// import { Form} from "react-router-dom";
// import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import Select from "react-select";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import ImageSharpIcon from '@mui/icons-material/ImageSharp';

const JobDraftInteraction = ({ jobId, DraftJob }) => {
  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [updateContent, setUpdateContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const inputFileRef = useRef(null);
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const [postData, setPostData] = useState("");
  // console.log('result', companyDetails);

  const PostsDetails = async () => {
    setShowModal(true);
    try {
      // const result = await GetProfileInfo();
      const result = await axios.get(`/Posts/GetPost/${jobId}?DeviceType=Web`);
      const Response = result.data.responseData.postContent;
      console.log("post data", result);
      setPostData(Response);
      //   setPrivacyType(result.data.responseData.privacyType)
      setPrivacyType({
        value:
          result.data.responseData.privacyType === "1"
            ? "Public"
            : " " || result.data.responseData.privacyType === "2"
              ? "Friends"
              : " " || result.data.responseData.privacyType === "3"
                ? "OnlyMe"
                : " " || result.data.responseData.privacyType === "4"
                  ? "Custom"
                  : " ",
        label: result.data.responseData.privacyType,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //************************Delete Post start  ************************//
  const [show, setShow] = useState(false);
  const handleDeleteClose = () => {
    setShow(false);
  };

  const deleteJob = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios
        .delete(`Job/DeleteJob`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShow(!show);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Delete Post End  ************************//

  const handleSubmit = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      postContent: updateContent,
      postCategory: 1,
      postOriginType: 1,
      privacyType: 1,
    };
    console.log(data);
    try {
      await axios.post(`Posts/UpdatePost/${jobId} `, data).then((resData) => {
        console.log(resData);
        setShowModal(false);
        // toast.success(resData.data.message);
        setToastMessage(resData.data.message);
        setShowToast(true);
        //   CheckConnectUser();
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const CompleteDraft = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    console.log(data);
    try {
      await axios.put(`Job/CompleteJobPosting`, data).then((resData) => {
        console.log(resData);
        setShowModal(false);
        // toast.success(resData.data.message);
        setToastMessage(resData.data.message);
        setShowToast(true);
        DraftJob();
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const UpdatePostAsDraft = async () => {
    try {
      // if (postContent !== "" && imageNames !== '') {
      const formData = new FormData();
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append(
        "PrivacyType",
        privacyType === "" ? "Public" : privacyType
      );
      formData.append("PostOriginType", "User");
      // Convert comma-separated imageNames string into an array
      const imageNameArray = imageNames
        .split(",")
        .filter((name) => name.trim() !== "");

      // Function to check if the list contains any video files
      const containsVideo = (names) => {
        const videoExtensions = [
          ".mp4",
          ".webm",
          ".avi",
          ".mov",
          ".mkv",
          ".flv",
          ".wmv",
        ];
        return names.some((name) =>
          videoExtensions.includes(
            name.slice(name.lastIndexOf(".")).toLowerCase()
          )
        );
      };

      // Determine PostCategory
      let postCategory;
      const hasPostContent = postContent !== "";
      const hasImages =
        imageNameArray.length > 0 && !containsVideo(imageNameArray);
      const hasVideos =
        imageNameArray.length > 0 && containsVideo(imageNameArray);

      if (hasPostContent && hasImages && hasVideos) {
        postCategory = "UserStory";
      } else if (hasPostContent && hasImages) {
        postCategory = "UserStory";
      } else if (hasPostContent && hasVideos) {
        postCategory = "UserStory";
      } else if (hasImages && hasVideos) {
        postCategory = "UserStory";
      } else if (hasPostContent) {
        postCategory = "Text";
      } else if (hasImages) {
        postCategory = "Image";
      } else if (hasVideos) {
        postCategory = "Video";
      } else {
        postCategory = "UserStory"; // Default case
      }

      formData.append("PostCategory", postCategory);
      formData.append("PostContent", postContent);
      formData.append("FilesToUpload", imageNames);
      formData.append("PostOriginID", userUid);
      formData.append("JobId", jobId);
      // console.log("formData", formData);

      const res = await axios.post("/Posts/UpdatePostDraft", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("response : ", res);
      // toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      // setJobId(res.data.responseData.id);
      setPostContent("");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [privacyType, setPrivacyType] = useState(null);

  const [showPost, setShowPost] = useState(false);

  useEffect(() => {
    if (showPost && inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, [showPost]);

  const handleShowPost = () => {
    setShowModal(false);
    setShowPost(true);
  };

  const checkDuplicate = (name) => {
    return images.some((image) => image.name === name);
  };

  const imageSelect = (event) => {
    // console.log("image selected");
    // setShowTestModal(true);
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const imageName = selectedFiles[i].name;
      if (!checkDuplicate(imageName)) {
        const imageUrl = URL.createObjectURL(selectedFiles[i]);
        setImages((prevImages) => [
          ...prevImages,
          {
            name: imageName,
            url: imageUrl,
            file: selectedFiles[i],
          },
        ]);
      } else {
        alert(`${imageName} is already added to the list`);
      }
    }
    event.target.value = null; // Reset file input
  };

  const [images, setImages] = useState([]);

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div
        key={index}
        className="image_container d-flex justify-content-center position-relative"
      >
        <img src={image.url} alt={image.name} />
        <span className="position-absolute" onClick={() => deleteImage(index)}>
          &times;
        </span>
      </div>
    ));
  };

  const Privacy = [
    { value: "1", label: "Public" },
    { value: "2", label: "Friends" },
    { value: "3", label: "OnlyMe" },
    { value: "4", label: "Custom" },
  ];

  const handleClosePost = () => {
    setShowPost(false);
  };

  const [postContent, setPostContent] = useState("");
  // const [jobId, setJobId] = useState("");
  const [display, setDisplay] = useState(false);
  const [imageNames, setImageNames] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (event) => {
    // console.log("image is selected");
    // if (jobId === '') {
    //   SavePostAsDraft()
    // } else {
    //   UpdatePostAsDraft()
    // }
    UpdatePostAsDraft();
    const files = Array.from(event.target.files);
    const fileNames = files.map((file) => file.name);
    setImageNames(fileNames.join(", "));
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setSelectedImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  const handleRemoveImage = (index) => {
    UpdatePostAsDraft();
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageNames((prevNames) => {
      const namesArray = prevNames.split(", ");
      namesArray.splice(index, 1);
      return namesArray.join(", ");
    });
  };
  useEffect(() => {
    if (show || showPost || showModal) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [show, showPost, showModal]);
  return (
    <>
      <div
        className="postMenu pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <MoreHorizSharpIcon className="material-symbols-sharp" />
        {showDropdown && (
          <Dropdown.Menu
            show={showDropdown}
            ref={dropdownRef}
            className="InteractionModal"
          >
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  CompleteDraft();
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <SaveSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Complete Job</span>
                </div>
                <p className="f-12 dropdownPara">
                  This Job will be saved in your feed
                </p>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  setShow(!show);
                  toggleDropdown();
                }}
              >
                <div className="d-flex align-items-center">
                  <DeleteOutlineSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Delete</span>
                </div>
                <p className="f-12 dropdownPara">
                  Once Deleted it can't be recovered
                </p>
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </div>

      {/* // Update Modal */}
      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modalBorder d-flex justify-content-between align-items-center">
                <h6>Post Something</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                />
              </div>
              <div className="d-flex align-items-center m-3 pb-3 modalBorder ">
                <div className="ImgAvatar">
                  <img src={NewsFeed.companyLogo} alt="" />
                </div>
                <p className="f-16  AddPost ms-2">What's on your mind?</p>
              </div>
              <div className="m-3">
                <textarea
                  autoFocus={true}
                  id="about"
                  placeholder="Update your post here"
                  name="about"
                  value={postData}
                  onChange={(e) => setPostData(e.target.value)}
                  //   invalid={display && postContent === ""}
                  rows="4"
                  cols="65"
                ></textarea>
              </div>
              <div className="m-3 d-flex align-items-center">
                <form className="form" action="#" method="post" id="form">
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    className="d-none"
                    onChange={imageSelect}
                  />
                  <ImageSharpIcon
                    className="material-symbols-sharp me-2 mt-2 pointer"
                    // onClick={() => document.getElementById("image").click()}
                    onClick={handleShowPost}
                  />
                </form>
                <button className="mainBtn ms-auto" onClick={handleSubmit}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPost && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex  align-items-center">
                <h6>Post Something</h6>
                <Select
                  defaultValue={privacyType}
                  onChange={(e) => {
                    setPrivacyType(e.label);
                  }}
                  options={Privacy}
                  placeholder={"Select Privacy"}
                  styles={customStyles}
                />
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    // if (postContent === "") {
                    //   setShowSecondModal(false);
                    // } else {
                    handleClosePost(false);
                    // }
                  }}
                />
              </div>
              <div className="d-flex align-items-center m-3 pb-3 modalBorder ">
                <div className="ImgAvatar">
                  <img src={NewsFeed.companyLogo} alt="" />
                </div>
                <p className="f-16  AddPost ms-2">What's on your mind?</p>
              </div>
              <div className="m-3">
                <textarea
                  autoFocus={true}
                  id="about"
                  placeholder="What do you want to talk about?"
                  name="about"
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  invalid={display && postContent === ""}
                  rows="4"
                  cols="65"
                  style={{ width: "100%" }}
                ></textarea>
              </div>

              <div className="modal-border d-flex justify-content-center align-items-center">
                <input
                  id="fileInput"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  multiple // Allow multiple file selection
                />

                {selectedImages.length > 0 && (
                  <div className="post-selected-images d-flex justify-content-center flex-wrap">
                    {selectedImages.map((imageUrl, index) => (
                      <div key={index} className="position-relative m-2">
                        <img
                          src={imageUrl}
                          alt={`Select Avtar  ${index + 1}`}
                          style={{
                            maxWidth: "200px",
                            maxHeight: "300px",
                            objectFit: "cover",
                          }}
                        />
                        <button
                          onClick={() => handleRemoveImage(index)}
                          className="btn btn-danger position-absolute top-0 end-0"
                          style={{
                            transform: "translate(50%, -50%)",
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0",
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="m-3 d-flex align-items-center">

                <form className="form" action="#" method="post" id="form">
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    className="d-none"
                    onChange={imageSelect}
                  />
                  <ImageSharpIcon
                    className="material-symbols-sharp me-2 mt-2 pointer"
                  />
                </form>

                <button
                  className="mainBtn ms-auto"
                  onClick={() => {
                    setShowPost(false);
                    UpdatePostAsDraft();
                  }}
                >
                  Update
                </button>
                {/* <div className="post-button m-2">
                
                {/* </div> */}
              </div>

              <div
                className="card-body d-flex flex-wrap justify-content-start"
                id="container"
              >
                {renderImages()}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <Modal
        show={show}
        size="md"
        centered
        onHide={handleDeleteClose}
        toggle={() => setShow(!show)}
      >
        <Modal.Header
          className="bg-transparent p-4"
          closeButton
          toggle={() => {
            setShow(!show);
          }}
        >
          <h6 className="f-16 fw-bold">Are you sure you want to delete Job?</h6>
        </Modal.Header>
        <Modal.Body className="text-center">
          <button color="" className="closebtn" onClick={() => deleteJob()}>
            Delete
          </button>
        </Modal.Body>
      </Modal> */}

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to delete Job?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => deleteJob()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default JobDraftInteraction;
