import React from 'react'
import Select from "react-select";
import { customStyles } from '../../selectStyles';
import NavigationSharpIcon from '@mui/icons-material/NavigationSharp';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import StoreSharpIcon from '@mui/icons-material/StoreSharp';
import PostAddSharpIcon from '@mui/icons-material/PostAddSharp';
import { NewsFeed } from '../../assets/Images/ImagesLink';
const SearchHeader = ({
    setReset,
    setSelectedCountryData,
    country,
    setSelectedCity,
    city,
    setSelectedCompanyData,
    companyList,
    setSelectedDesignationData,
    designationList

}) => {

    //************************ Country Filter  ************************//
    let countryOptions = country?.map((val) => {
        return { value: val.id, label: val.name };
    });

    function handlecountryChange(selectedOptions) {
        setReset(true);
        const selectedValues = selectedOptions?.map((item) => item.value);
        // console.log("country got", selectedValues);
        setSelectedCountryData(selectedValues);
        // AllJobList();
    }
    //************************ Country Filter  ************************//

    //************************ City Filter  ************************//
    let cityOptions = city?.map((val) => {
        return { value: val.id, label: val.name };
    });

    function handleCityChange(selectedOptions) {
        setReset(true);
        const selectedValues = selectedOptions?.map((item) => item.value);
        // console.log("city got", selectedValues);
        setSelectedCity(selectedValues);
        // AllJobList();
    }
    //************************ City Filter  ************************//


    //************************ Company Filter  ************************//
    let companyListOptions = companyList?.map((val) => {
        return { value: val.id, label: val.name };
    });
    function handleCompanyChange(selectedOptions) {
        setReset(true);
        // AllJobList();
        const selectedValues = selectedOptions?.map((item) => item.value);
        setSelectedCompanyData(selectedValues);
        // AllJobList();
    }
    //************************ Company Filter  ************************//


    //************************ Designation Filter  ************************//
    let designationListOptions = designationList?.map((val) => {
        return { value: val.id, label: val.name };
    });

    function handleDesignationChange(selectedOptions) {
        setReset(true); // Set reset to true first
        const selectedValues = selectedOptions?.map((item) => item.value);
        setSelectedDesignationData(selectedValues);
    }
    //************************ Designation Filter  ************************//

    return (
        <>

            <div>
                <img src={NewsFeed.AdImg2} className='jobAd' />
            </div>
            <div className="d-block d-md-flex jobSearch jobdropdown mb-2">
                <div className="col-md-3 mb-1">
                    <div className="searchInput d-flex justify-content-center align-items-center p-2">
                        <NavigationSharpIcon className="material-symbols-sharp" />
                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={countryOptions}
                            placeholder="Select Country"
                            onChange={handlecountryChange}
                            loadOptions={handlecountryChange}
                            styles={customStyles}

                        />
                    </div>
                </div>
                <div className="col-md-3 mb-1">

                    <div className="searchInput d-flex justify-content-center align-items-center p-2">
                        <ApartmentSharpIcon className="material-symbols-sharp" />
                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={cityOptions}
                            onChange={handleCityChange}
                            loadOptions={handleCityChange}
                            placeholder="Select city"
                            styles={customStyles}
                        />

                    </div>
                </div>
                <div className="col-md-3 mb-1">
                    <div className="searchInput d-flex p-2 justify-content-center align-items-center">
                        <StoreSharpIcon className="material-symbols-sharp" />
                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={companyListOptions}
                            onChange={handleCompanyChange}
                            loadOptions={handleCompanyChange}
                            placeholder="Select company"
                            styles={customStyles}

                        />
                    </div>
                </div>
                <div className="col-md-3 mb-1">
                    <div className="searchInput d-flex justify-content-center align-items-center p-2">
                        <PostAddSharpIcon className="material-symbols-sharp" />

                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={designationListOptions}
                            placeholder="Designation"
                            onChange={handleDesignationChange}
                            loadOptions={handleDesignationChange}
                            styles={customStyles}

                        />
                    </div>
                </div>
                {/* <button className="mainBtn1">Search</button> */}
            </div>
        </>
    )
}

export default SearchHeader