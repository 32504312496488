import React, { useEffect, useState } from "react";
import "../../assets/css/notification.css";
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { post } from "../../assets/Images/ImagesLink";
import moment from "moment";
import { Link } from "react-router-dom";
import NotificationAction from "./NotificationAction";
import { ShimmerDiv } from "shimmer-effects-react";
import WorkOutlineSharpIcon from '@mui/icons-material/WorkOutlineSharp';
import ChatBubbleOutlineSharpIcon from '@mui/icons-material/ChatBubbleOutlineSharp';
import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';

const Notification = () => {
  const sessionId = localStorage.getItem("sessionId");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [noticationRead, setNoticationRead] = useState(false);
  const [notificationList, setNotificationList] = useState([])
  console.log('notificationList', notificationList);
  const NotificationList = async (page) => {
    const data = {
      "deviceType": "Web",
      "sessionId": sessionId,
      "pageNumber": page,
      "pageSize": 12
    }
    try {
      setLoading(true);
      await axios
        .post(
          `V1/General/GetAllNotifications`, data
        )
        .then((resData) => {
          console.log("resDataNew", resData.data.responseData);
          setIsLoading(false);
          setTotalPages(resData.data.responseData.paging.totalPages);

          const newnoti = resData.data.responseData.notificationsList;
          setNotificationList((prev) => {
            const postSet = new Set(prev.map((post) => post.ntfctnId));
            const uniquenewnoti = newnoti.filter(
              (post) => !postSet.has(post.ntfctnId)
            );
            return [...prev, ...uniquenewnoti];
          });
          setLoading(false);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.log(error);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (page <= totalPages) {
      NotificationList(page);
    }
  }, [page]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);



  const ReadNotification = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId
    };
    try {
      await axios
        .post(`V1/General/MarkNotificationAsRead?ID=${id}`, responseData)
        .then((res) => {
          // setShow(!show);
          // // toast.success(res.data.message);
          // setToastMessage(res.data.message);
          // setShowToast(true);

        });
    } catch (e) {
      console.log(e.response);
      // if (e.response && e.response.status === 404) {
      //   setWarningToastMessage(e.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(e.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };

  const markAsRead = (id) => {
    setNotificationList((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.ntfctnId === id ? { ...notification, isView: false } : notification
      )
    );
  };

  return (
    <>
      <div className="notification-main-container">
        <div className="notification-header d-flex align-items-center">
          <div className="notificationMainText me-2">
            <h5>Notification</h5>
          </div>
          {/* <div className="notificationDropdown"><h5>Dropdown</h5></div> */}
        </div>
        <div className="notification-messages">

          {isLoading ? (
            <div className="mt-3">
              {/* <ShimmerThumbnail height={250} rounded width={"50px"} /> */}
              <ShimmerDiv mode="light" height={250} width={'50px'} />
            </div>
          )
            : isError ? (
              <div className="mt-2 error-image-cont">
                <img src={post.errorbig} alt="404 Not Found" />
              </div>
            ) : (
              notificationList &&
              notificationList
                .filter((item) => !item.isDeleted)
                ?.map((item, index) => {
                  console.log("postList", item);

                  const now = moment();
                  const itemTime = moment.utc(item?.time);
                  const duration = moment.duration(now.diff(itemTime));

                  const years = duration.years();
                  const months = duration.months();
                  const days = duration.days();
                  const hours = duration.hours();
                  const minutes = duration.minutes();

                  let result = '';

                  if (years > 0) {
                    result = `${years} year${years > 1 ? 's' : ''} ago`;
                  } else if (months > 0) {
                    result = `${months} month${months > 1 ? 's' : ''} ago`;
                  } else if (days > 0) {
                    result = `${days} day${days > 1 ? 's' : ''} ago`;
                  } else if (hours > 0) {
                    result = `${hours} hour${hours > 1 ? 's' : ''} ago`;
                  } else if (minutes > 0) {
                    result = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
                  } else {
                    result = 'just now';
                  }

                  console.log(result);


                  return (
                    <>

                      {item?.isView === false ? item?.type === 3 || item?.type === 12 || item?.type === 13 || item?.type === 14 || item?.type === 15 || item?.type === 16 ?
                        <div className="custom-message-card unreadNotification d-flex justify-content-between align-items-center p-2">
                          <Link to={`/User/${item?.fromUserUid}`} onClick={(e) => {
                            ReadNotification(item?.ntfctnId);
                          }}>
                            <div className="msgLeft d-flex">
                              <div className="ImgAvatar me-3">
                                <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                              </div>
                              <div className="messageCardContent">
                                <div className="messageMainText"><h1>{item?.fromUserFname} <span className="textStyle">{item?.content}</span> </h1></div>
                                <div className="messageSubText"><h1>{result}</h1></div>
                              </div>
                            </div>
                          </Link>
                          <div className="msgRight d-flex align-items-center">
                            <Link to={`/User/${item?.fromUserUid}`} onClick={(e) => {
                              ReadNotification(item?.ntfctnId);
                            }}>
                              {item?.typeName === "JobPost" ? (
                                <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                  <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                </div>
                              ) : item?.typeName === "Message" ? (
                                <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                  <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                </div>
                              ) : item?.typeName === "Like" ? (
                                <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                  <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                </div>
                              ) : (
                                ""
                              )}

                            </Link>

                            <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                          </div>
                        </div> : item?.type === 1 ?
                          <div className="custom-message-card unreadNotification d-flex justify-content-between align-items-center p-2">
                            <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/Company/${item?.fromUserUid}`}>
                              <div className="msgLeft d-flex">
                                <Link to={`/Company/${item?.fromUserUid}`}>
                                  <div className="ImgAvatar me-3">
                                    <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                  </div>
                                </Link>
                                <div className="messageCardContent">
                                  <div className="messageMainText"><h1>{item?.fromUserFname} <span className="textStyle">{item?.content}</span> </h1></div>
                                  <div className="messageSubText"><h1>{result}</h1></div>
                                </div>
                              </div>
                            </Link>
                            <div className="msgRight d-flex align-items-center">
                              <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/Company/${item?.fromUserUid}`}>
                                {item?.typeName === "JobPost" ? (
                                  <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                    <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                  </div>
                                ) : item?.typeName === "Message" ? (
                                  <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                    <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                  </div>
                                ) : item?.typeName === "Like" ? (
                                  <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                    <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                  </div>
                                ) : (
                                  ""
                                )}

                              </Link>
                              <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                            </div>
                          </div>
                          : item?.type === 2 ?
                            <div className="custom-message-card unreadNotification d-flex justify-content-between align-items-center p-2">
                              <Link onClick={(e) => {
                                ReadNotification(item?.ntfctnId);
                              }} to={`/Message`}>
                                <div className="msgLeft d-flex">
                                  <Link to={`/User/${item?.fromUserUid}`}>
                                    <div className="ImgAvatar me-3">
                                      <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                    </div>
                                  </Link>
                                  <div className="messageCardContent">
                                    <div className="messageMainText"><h1><Link to={`/User/${item?.fromUserUid}`} className="me-2">{item?.fromUserFname}</Link><span className="textStyle">{item?.content}</span> </h1></div>
                                    <div className="messageSubText"><h1>{result}</h1></div>
                                  </div>
                                </div>
                              </Link>
                              <div className="msgRight d-flex align-items-center">
                                <Link onClick={(e) => {
                                  ReadNotification(item?.ntfctnId);
                                }} to={`/Message`}>
                                  {item?.typeName === "JobPost" ? (
                                    <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                      <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                    </div>
                                  ) : item?.typeName === "Message" ? (
                                    <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                      <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                    </div>
                                  ) : item?.typeName === "Like" ? (
                                    <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                      <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                </Link>
                                <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                              </div>
                            </div>
                            : item?.type === 4 || item?.type === 5 || item?.type === 6 || item?.type === 7 || item?.type === 8 || item?.type === 9 || item?.type === 10 || item?.type === 11 || item?.type === 24 || item?.type === 18 ?
                              <div className="custom-message-card unreadNotification d-flex justify-content-between align-items-center p-2">
                                <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/Post/${item?.refLinkId}`}>
                                  <div className="msgLeft d-flex">
                                    <Link to={`/User/${item?.fromUserUid}`}>
                                      <div className="ImgAvatar me-3">
                                        <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                      </div>
                                    </Link>
                                    <div className="messageCardContent">
                                      <div className="messageMainText"><h1><Link to={`/User/${item?.fromUserUid}`} className="me-2"> {item?.fromUserFname}</Link> <span className="textStyle">{item?.content}</span> </h1></div>
                                      <div className="messageSubText"><h1>{result}</h1></div>
                                    </div>
                                  </div>
                                </Link>
                                <div className="msgRight d-flex align-items-center">
                                  <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/Post/${item?.refLinkId}`}>

                                    {item?.typeName === "JobPost" ? (
                                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                      </div>
                                    ) : item?.typeName === "Message" ? (
                                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                      </div>
                                    ) : item?.typeName === "Like" ? (
                                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Link>
                                  <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                                </div>
                              </div>
                              : item?.type === 17 || item?.type === 19 || item?.type === 20 || item?.type === 21 || item?.type === 22 || item?.type === 23 || item?.type === 25 || item?.type === 26 ?
                                <div className="custom-message-card  unreadNotification d-flex justify-content-between align-items-center p-2">
                                  <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/JobDetail/${item?.refLinkId}`}>
                                    <div className="msgLeft d-flex">
                                      <Link to={`/Company/${item?.fromUserUid}`}>
                                        <div className="ImgAvatar me-3">
                                          <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                        </div>
                                      </Link>
                                      <div className="messageCardContent">
                                        <div className="messageMainText"><h1> <Link to={`/Company/${item?.fromUserUid}`}>{item?.fromUserFname}</Link> <span className="textStyle">{item?.content}</span> </h1></div>
                                        <div className="messageSubText"><h1>{result}</h1></div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="msgRight d-flex align-items-center">
                                    <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/JobDetail/${item?.refLinkId}`}>

                                      {item?.typeName === "JobPost" ? (
                                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : item?.typeName === "Message" ? (
                                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : item?.typeName === "Like" ? (
                                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Link >
                                    <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                                  </div>
                                </div>
                                :
                                <div className="custom-message-card unreadNotification d-flex justify-content-between align-items-center p-2">
                                  <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/User/${item?.fromUserUid}`}>
                                    <div className="msgLeft d-flex">
                                      <div className="ImgAvatar me-3">
                                        <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                      </div>
                                      <div className="messageCardContent">
                                        <div className="messageMainText"><h1>{item?.fromUserFname} <span className="textStyle">{item?.content}</span> </h1></div>
                                        <div className="messageSubText"><h1>{result}</h1></div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="msgRight d-flex align-items-center">
                                    <Link onClick={() => { ReadNotification(item?.ntfctnId) }} to={`/User/${item?.fromUserUid}`}>

                                      {item?.typeName === "JobPost" ? (
                                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : item?.typeName === "Message" ? (
                                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : item?.typeName === "Like" ? (
                                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Link>
                                    <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                                  </div>
                                </div>
                        : item?.type === 3 || item?.type === 12 || item?.type === 13 || item?.type === 14 || item?.type === 15 || item?.type === 16 ?
                          <div className="custom-message-card  d-flex justify-content-between align-items-center p-2">
                            <Link to={`/User/${item?.fromUserUid}`}>
                              <div className="msgLeft d-flex">
                                <div className="ImgAvatar me-3">
                                  <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                </div>
                                <div className="messageCardContent">
                                  <div className="messageMainText"><h1>{item?.fromUserFname} <span className="textStyle">{item?.content}</span> </h1></div>
                                  <div className="messageSubText"><h1>{result}</h1></div>
                                </div>
                              </div>
                            </Link>
                            <div className="msgRight d-flex align-items-center">
                              <Link to={`/User/${item?.fromUserUid}`}>

                                {item?.typeName === "JobPost" ? (
                                  <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                    <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                  </div>
                                ) : item?.typeName === "Message" ? (
                                  <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                    <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                  </div>
                                ) : item?.typeName === "Like" ? (
                                  <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                    <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Link>
                              <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                            </div>
                          </div>
                          : item?.type === 1 ?
                            <div className="custom-message-card  d-flex justify-content-between align-items-center p-2">
                              <Link to={`/Company/${item?.fromUserUid}`}>
                                <div className="msgLeft d-flex">
                                  <div className="ImgAvatar me-3">
                                    <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                  </div>
                                  <div className="messageCardContent">
                                    <div className="messageMainText"><h1>{item?.fromUserFname} <span className="textStyle">{item?.content}</span> </h1></div>
                                    <div className="messageSubText"><h1>{result}</h1></div>
                                  </div>
                                </div>
                              </Link>
                              <div className="msgRight d-flex align-items-center">
                                <Link to={`/Company/${item?.fromUserUid}`}>

                                  {item?.typeName === "JobPost" ? (
                                    <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                      <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                    </div>
                                  ) : item?.typeName === "Message" ? (
                                    <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                      <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                    </div>
                                  ) : item?.typeName === "Like" ? (
                                    <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                      <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Link>
                                <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                              </div>
                            </div>
                            : item?.type === 2 ?

                              <div className="custom-message-card  d-flex justify-content-between align-items-center p-2">
                                <Link to={`/Message`}>
                                  <div className="msgLeft d-flex">
                                    <Link to={`/User/${item?.fromUserUid}`}>
                                      <div className="ImgAvatar me-3">
                                        <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                      </div>
                                    </Link>
                                    <div className="messageCardContent">
                                      <div className="messageMainText"><h1><Link to={`/User/${item?.fromUserUid}`} className="me-2">{item?.fromUserFname}</Link> <span className="textStyle">{item?.content}</span> </h1></div>
                                      <div className="messageSubText"><h1>{result}</h1></div>
                                    </div>
                                  </div>
                                </Link>
                                <div className="msgRight d-flex align-items-center">
                                  <Link to={`/Message`}>

                                    {item?.typeName === "JobPost" ? (
                                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                      </div>
                                    ) : item?.typeName === "Message" ? (
                                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                      </div>
                                    ) : item?.typeName === "Like" ? (
                                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Link>
                                  <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                                </div>
                              </div>
                              : item?.type === 4 || item?.type === 5 || item?.type === 6 || item?.type === 7 || item?.type === 8 || item?.type === 9 || item?.type === 10 || item?.type === 11 || item?.type === 24 || item?.type === 18 ?
                                <div className="custom-message-card  d-flex justify-content-between align-items-center p-2">
                                  <Link to={`/Post/${item?.refLinkId}`}>
                                    <div className="msgLeft d-flex">
                                      <Link to={`/User/${item?.fromUserUid}`}>
                                        <div className="ImgAvatar me-3">
                                          <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                        </div>
                                      </Link>
                                      <div className="messageCardContent">
                                        <div className="messageMainText"><h1><Link to={`/User/${item?.fromUserUid}`} className="me-2"> {item?.fromUserFname}</Link> <span className="textStyle">{item?.content}</span> </h1></div>
                                        <div className="messageSubText"><h1>{result}</h1></div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="msgRight d-flex align-items-center">
                                    <Link to={`/Post/${item?.refLinkId}`}>

                                      {item?.typeName === "JobPost" ? (
                                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : item?.typeName === "Message" ? (
                                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : item?.typeName === "Like" ? (
                                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Link>
                                    <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                                  </div>
                                </div>
                                : item?.type === 17 || item?.type === 19 || item?.type === 20 || item?.type === 21 || item?.type === 22 || item?.type === 23 || item?.type === 25 || item?.type === 26 ?
                                  <div className="custom-message-card hjhdkah  d-flex justify-content-between align-items-center p-2">
                                    <Link to={`/JobDetail/${item?.refLinkId}`}>
                                      <div className="msgLeft d-flex">
                                        <Link to={`/Company/${item?.fromUserUid}`}>
                                          <div className="ImgAvatar me-3">
                                            <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                          </div>
                                        </Link>
                                        <div className="messageCardContent">
                                          <div className="messageMainText"><h1><Link to={`/Company/${item?.fromUserUid}`}> {item?.fromUserFname}</Link> <span className="textStyle">{item?.content}</span> </h1></div>
                                          <div className="messageSubText"><h1>{result}</h1></div>
                                        </div>
                                      </div>
                                    </Link>
                                    <div className="msgRight d-flex align-items-center">
                                      <Link to={`/JobDetail/${item?.refLinkId}`}>

                                        {item?.typeName === "JobPost" ? (
                                          <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                            <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                          </div>
                                        ) : item?.typeName === "Message" ? (
                                          <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                            <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                          </div>
                                        ) : item?.typeName === "Like" ? (
                                          <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                            <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                      <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                                    </div>
                                  </div>
                                  :
                                  <div className="custom-message-card  d-flex justify-content-between align-items-center p-2">
                                    <Link to={`/User/${item?.fromUserUid}`}>
                                      <div className="msgLeft d-flex">
                                        <div className="ImgAvatar me-3">
                                          <img src={item?.fromProfilPic} alt="" style={{ borderRadius: '50px' }} />
                                        </div>
                                        <div className="messageCardContent">
                                          <div className="messageMainText"><h1>{item?.fromUserFname} <span className="textStyle">{item?.content}</span> </h1></div>
                                          <div className="messageSubText"><h1>{result}</h1></div>
                                        </div>
                                      </div>
                                    </Link>
                                    <div className="msgRight d-flex align-items-center">
                                      <Link to={`/User/${item?.fromUserUid}`}>

                                        {item?.typeName === "JobPost" ? (
                                          <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                                            <WorkOutlineSharpIcon className="material-symbols-outlined" />
                                          </div>
                                        ) : item?.typeName === "Message" ? (
                                          <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                                            <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                                          </div>
                                        ) : item?.typeName === "Like" ? (
                                          <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                                            <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                      <NotificationAction markAsRead={markAsRead} noticationRead={noticationRead} setNoticationRead={setNoticationRead} notifiID={item?.ntfctnId} NotificationList={NotificationList} page={page} item={item} />
                                    </div>
                                  </div>
                      }

                    </>
                  );
                })
            )}



        </div>
      </div>
    </>
  );
};

export default Notification;
