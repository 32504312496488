import React, { useEffect, useRef, useState } from "react";
import { NewsFeed } from "../../assets/Images/ImagesLink";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import { Formik } from "formik";
import ProgressBars from "../Articles/ProgressBars";
import DatePicker from "react-datepicker";
import axios from "../../API/axios";
import ReactSelect from "react-select";
import { userAuthImage } from "../../assets/Images/ImagesLink";

const FeedAdvertise = () => {
  return (
    <>
      <div className="advertiseImage">
        <img src={NewsFeed.Advertise} alt="" />
      </div>
      {/* <div className="featured-card mt-2">
        <div className="feature-header p-2 d-flex justify-content-between">
          <h4 className="f-14 fw-bold">Featured Company</h4>
          <a href="" className="f-12 fw-bold">
            SEE ALL
          </a>
        </div>
        <div className="feature-content p-2 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div className="ImgAvatar">
              <img src={NewsFeed.companyLogo} alt="" />
            </div>
            <div className="ms-1">
              <h4 className="f-14 fw-bold mb-0">Amazon</h4>
              <p className="f-12">E-commerce </p>
            </div>
          </div>
          <button className="mainbtn3">
             Follow
          </button>
        </div>
        <div className="feature-post p-2">
          <img src={NewsFeed.FeaturedPost} alt="" />
        </div>
        

      </div> */}
    </>
  );
};

export default FeedAdvertise;
