import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { NewsFeed, people } from "../../assets/Images/ImagesLink";
import EmojiPicker from "emoji-picker-react";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import AddReactionSharpIcon from '@mui/icons-material/AddReactionSharp';
const CommentReply = ({
  item,
  postId,
  setPostList,
  toggleReplyComment
}) => {
  console.log("nbbs", item);
  const sessionId = localStorage.getItem("sessionId");
  const profilPic = localStorage.getItem("profilPic");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  //************************Post Comment  ************************//
  const [content, setContent] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const postData = async () => {
    const postdatadetails = {
      deviceType: "Web",
      sessionId: sessionId,
      pstCmntId: item.pstCmntId,
      reply: content,
    };
    try {
      const res = await axios.post("PostAction/CommentReply", postdatadetails);
      setContent("");
      setToastMessage(res.data.message);
      setShowToast(true);
      // updateCommentCount(postId, 1)
      // const responseData = res.data.responseData;
      // AllPostList()
      toggleReplyComment()
      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
      const updatedPost = postDetailsResponse.data.responseData;
      // console.log('updatedPost', updatedPost);

      // Update the existing post in the list
      setPostList((prev) =>
        prev.map((post) =>
          post.postId === updatedPost.postId ? updatedPost : post
        )
      );
    } catch (error) {
      setErrorToastMessage(error.message);
      setShowErrorToast(true)
      //   console.log("ERRR",error.message);

    }

  };

  const handleKeyPress = () => {
    postData();
  };
  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const handleEmojiClose = () => {
    setShowEmojiPicker(false);
  };
  //************************Post Comment  ************************//
  // const profilPic = localStorage.getItem("profilPic");
  const guestModeData = localStorage.getItem("guestMode");
  return (
    <>
      {guestModeData === "true" ? (
        ""
      ) :
        <div className="ReplyComment mt-2">
          <div className="commentArea ">
            <div className="ImgAvatar1">
              <img src={profilPic} alt="" style={{ borderRadius: '50px' }} />
            </div>
            <div className="commentBox">
              <input
                type="text"
                placeholder="Write your comment…"
                value={content}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleKeyPress();
                  }
                }}
              />
              <div>
                <AddReactionSharpIcon
                  className="material-symbols-sharp me-2"
                  onClick={handleEmojiClick}
                  style={{ cursor: "pointer" }}
                />

                <SendSharpIcon
                  className="material-symbols-sharp"
                  onClick={() => postData()}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {showEmojiPicker && (
            <div>
              <EmojiPicker
                onEmojiClick={(e, emojiObject) => {
                  setContent(content + e.emoji);
                  setShowEmojiPicker(false);
                }}
              />
              <div
                className="emoji-close"
                onClick={handleEmojiClose}
                style={{ cursor: "pointer" }}
              >
                <CloseSharpIcon className="material-symbols-sharp" />
              </div>
            </div>
          )}
        </div>
      }
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default CommentReply;
