// import React from 'react';
// import { Navigate } from 'react-router-dom';

// // Component to handle redirection based on authentication
// const PrivateRoute = ({ element, redirectTo }) => {
//     const token = localStorage.getItem("token");
//     console.log(token);

//     return token ? <Navigate to={redirectTo} replace /> : element;
// };

// export default PrivateRoute;



import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, isProtected, redirectTo }) => {
    const token = localStorage.getItem("token");
    const guestMode = localStorage.getItem("guestMode");

    // If the route is protected and the user is not authenticated or not in guest mode, redirect to sign-in
    if (isProtected && !token && !guestMode) {
        return <Navigate to="/SignIn" replace />;
    }

    // If the user is authenticated, prevent access to SignIn/SignUp
    if (!isProtected && token) {
        return <Navigate to="/" replace />;
    }


    // if (guestMode) {
    //     const restrictedRoutes = ['/Draft', '/MyConnections', '/ManageJobs', '/JobApplications', '/Notification', '/Message', '/Settings/profile', '/Settings/changePassword', '/Settings/blockedUser', '/Settings/account'];
    //     if (restrictedRoutes.includes(window.location.pathname)) {
    //         return <Navigate to="/NewsFeed" replace />;
    //     }
    // }
    if (guestMode) {
        const restrictedRoutes = ['/Draft', '/MyConnections', '/ManageJobs', '/JobApplications', '/Notification', '/Message', '/Settings/profile', '/Settings/changePassword', '/Settings/blockedUser', '/Settings/account'];
        const currentPath = window.location.pathname;

        // Check if the current path is restricted or starts with restricted routes
        const isRestrictedPath = restrictedRoutes.some(route => currentPath.startsWith(route)) ||
            currentPath.startsWith('/Message/');

        if (isRestrictedPath) {
            return <Navigate to="/Newsfeed" replace />;
        }
    }

    // If in guest mode, let them access protected routes but with limited functionality
    return <Component />;
};

export default PrivateRoute;




