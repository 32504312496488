import React, { useEffect, useRef, useState } from "react";
// import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/connection.css";
import { Button, Col, Dropdown, Modal, NavLink, Row } from "react-bootstrap";
import locationIcon from "../../assets/Images/connection/locationIcon.png";
import userAvatar from "../../assets/Images/connection/Group 647.png";
import axios from "../../API/axios";
import { post, NewsFeed } from "../../assets/Images/ImagesLink";
import Loading from "../Blogs/Loading";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Link, useLocation } from "react-router-dom";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { ShimmerDiv } from "shimmer-effects-react";
import RecommendationAction from "./RecommendationAction";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
import ReportGmailerrorredSharpIcon from '@mui/icons-material/ReportGmailerrorredSharp';



const Connection = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sentPage, setSentPage] = useState(1);
  const [recievePage, setRecievePage] = useState(1);
  const [isError, setIsError] = useState(false);
  const [sentIsError, setSentIsError] = useState(false);
  const [receivedIsError, setReceivedIsError] = useState(false);
  const [recommendedIsError, setRecommendedIsError] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  const [searchUserName, setSearchUserName] = useState("");
  const [searchconnectList, setSearchconnectList] = useState([]);

  const options = [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Thane", label: "Thane" },
    { value: "Pune", label: "Pune" },
  ];

  const sessionId = localStorage.getItem("sessionId");
  //************************ Connection  List   ************************//
  const [ConnectionList, setConnectionList] = useState([]);

  const ConnectionListAll = async () => {
    // console.log('calles');
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: page,
      pageSize: 10,
      type: "ConnectionList",
      search: searchUserName,
    };
    try {
      await axios.post("Connection/GetConnectionList", data).then((resData) => {
        setIsLoading(false);
        if (searchUserName !== "") {
          setConnectionList([]);
          setSearchconnectList(resData?.data?.responseData?.viewList);
        } else {
          setSearchconnectList([]);
          console.log("resData.data.responseData.viewList", resData);
          if (resData.status === 204) {
            setIsError(true)
          } else {
            setConnectionList((prev) => {
              const newViewList = resData?.data?.responseData?.viewList.filter(
                (item) =>
                  !prev.some((prevItem) => prevItem.cnctnId === item.cnctnId)
              );
              return [...prev, ...newViewList];
            });
          }
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.log(error);
      }
      console.log(error);
    }
  };
  useEffect(() => {
    ConnectionListAll();
  }, [page, searchUserName]);

  //************************Connection List ************************//

  const [recommendationList, setRecommendationList] = useState([]);
  const [recommendedPage, setRecommendedPage] = useState(1);
  //************************ Get Recommendations List  ************************//
  const GetRecommendation = async (recommendedPage) => {
    try {
      const result = await axios.post(`UserFollow/GetRecommendationList`, {
        sessionId,
        deviceType: "Web",
        pageNumber: recommendedPage,
        pageSize: 8,
      });
      setIsLoading(false);
      const Response = result.data.responseData.viewList;
      setRecommendationList((prev) => {
        const postSet = new Set(prev.map((post) => post.userUid));
        const uniqueNewPosts = Response.filter(
          (post) => !postSet.has(post.userUid)
        );
        return [...prev, ...uniqueNewPosts];
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 404) {
        setRecommendedIsError(true);
      } else {
        console.log(error);
      }
      setIsLoading(false);
    }
  };
  useEffect(() => {
    GetRecommendation();
  }, [recommendedPage]);

  //************************  Get Recommendations List End  ************************//

  //************************Sent Connect Request List   ************************//
  const [sentRequest, setsentRequest] = useState([]);
  const sentRequestList = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: sentPage,
      pageSize: 100,
      type: "SentRequestList",
    };

    console.log("resdataSentRequestList", data);

    try {
      await axios.post("Connection/GetConnectionList", data).then((resData) => {
        console.log("resdataSentRequestList", resData);

        setIsLoading(false);
        if (resData?.status === 204) {
          setSentIsError(true);
        } else {
          setsentRequest((prev) => {
            const newViewList = resData?.data?.responseData?.viewList.filter(
              (item) =>
                !prev.some((prevItem) => prevItem.cnctnId === item.cnctnId)
            );
            return [...prev, ...newViewList];
          });
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setSentIsError(true);
      } else {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    sentRequestList();
  }, [sentPage]);
  //************************Sent Connect Request List ************************//

  //************************Received Connect Request List   ************************//
  const [RecievedRequest, setRecievedRequest] = useState([]);
  const ReceivedRequestList = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: recievePage,
      pageSize: 100,
      type: "ReceivedRequestList",
    };
    try {
      await axios.post("Connection/GetConnectionList", data).then((resData) => {
        console.log(" ReceivedRequestList", resData.status);
        setIsLoading(false);
        if (resData?.status === 204) {
          setReceivedIsError(true);
        } else {
          setRecievedRequest((prev) => {
            const newViewList = resData?.data?.responseData?.viewList.filter(
              (item) =>
                !prev.some((prevItem) => prevItem.cnctnId === item.cnctnId)
            );
            return [...prev, ...newViewList];
          });
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setReceivedIsError(true);
      } else {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ReceivedRequestList();
  }, [recievePage]);

  //************************Received Connect Request List ************************//

  //************************Cancel Request   ************************//
  const [show, setShow] = useState(false);
  const cancelRequestClose = () => {
    setShow(false);
  };
  const [userUID, setuserUID] = useState("");
  const CancelRequest = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: userUID,
    };

    try {
      await axios
        .post("Connection/CancelConnectionRequest", data)
        .then((resData) => {
          setShow(!show);
          setToastMessage(resData.data.message);
          setShowToast(true);
          setsentRequest((prev) => prev.filter((item) => item.cnctnId !== id));
          sentRequestList();
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Remove Connection Request   ************************//

  const RemoveRequest = async (id) => {
    const Removedata = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: selectedUserId,
    };
    console.log("remove id", Removedata);
    try {
      await axios
        .post("Connection/DisconnectUser ", Removedata)
        .then((resData) => {
          console.log("remove res", resData);
          //   setShowRemove(!showRemove);
          //   toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          ConnectionListAll();
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Connection Request  ************************//

  //************************Accept Request   ************************//
  const AcceptRequest = async (userId, cnctnId) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: userId,
      // connectID: cnctnId,
      actionType: "Accept",
    };
    console.log('cnctnId', data);

    try {
      await axios
        .post("Connection/ConnectionRequestAction ", data)
        .then((resData) => {
          setToastMessage(resData.data.message);
          setShowToast(true);
          setRecievedRequest((prev) => prev.filter((item) => item.userUid !== userId));
          // setRecievedRequest((prev) => {
          //   const newViewList = resData?.data?.responseData?.viewList.filter(
          //     (item) =>
          //       !prev.some((prevItem) => prevItem.cnctnId === cnctnId)
          //   );
          //   return [...prev, ...newViewList];
          // });
          ReceivedRequestList();
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Accept Request ************************//

  //************************Reject Request   ************************//
  const RejectRequest = async (userId, cnctnId) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: userId,
      // connectID: cnctnId,
      actionType: "Reject",
    };
    // console.log(data);
    try {
      await axios
        .post("Connection/ConnectionRequestAction ", data)
        .then((resData) => {
          setToastMessage(resData.data.message);
          setShowToast(true);
          setRecievedRequest((prev) => prev.filter((item) => item.userUid !== userId));
          ReceivedRequestList();
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Reject Request ************************//

  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = (index) => {
    setShowDropdown((prev) => (prev === index ? null : index));
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleShowModal = (item) => {
    setSelectedItem(item);
    setSelectedUserId(item.userUid);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleConfirmAction = () => {
    RemoveRequest();
    handleCloseModal();
  };

  //************************ short mutual list ************************//
  const [shortMutualList, setShortMutualList] = useState({});
  console.log('shortMutualList', shortMutualList);

  useEffect(() => {
    if (recommendationList && recommendationList.length > 0) {
      recommendationList.forEach((item) => {
        fetchShortMutualList(item.userUid);
      });
    }
  }, [recommendationList]);

  const fetchShortMutualList = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUid: id,
    };
    try {
      const response = await axios.post(
        "UserFollow/GetShortMutualUserInfo",
        data
      );
      setShortMutualList((prevState) => ({
        ...prevState,
        [id]: response.data.responseData,
      }));
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // setIsError(true);
      } else {
        console.log(error);
      }
      setIsLoading(false);
    }
  };
  //************************ short mutual list ************************//

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <div className="mb-2">
              <input
                type="email"
                className="form-control p-2 connection-input"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Search user"
                onChange={(e) => {
                  setSearchUserName(e.target.value);
                }}
                value={searchUserName}
              />
            </div>
            {isLoading ? (
              <div className="mt-3">
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
              </div>
            ) : isError || (searchconnectList?.length === undefined && ConnectionList?.length === 0) ? (
              <div className="error-connection">
                <img src={post.errorSmall} alt="404 Not Found" />
              </div>
            ) : searchconnectList?.length === 0 ? (
              ConnectionList &&
              ConnectionList?.map((item, index) => {
                console.log("mainconnection", item);
                return (
                  <div key={index}>
                    <Row>
                      <div className="resume-cont d-block d-md-flex">
                        <Col md={2} className="m-auto">
                          <Link to={`/User/${item.userUid}`}>
                            <div className="image-cont">
                              <img
                                src={item?.profilPic}
                                alt=""
                                width={70}
                                height={70}
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                          </Link>
                        </Col>
                        <Col md={7}>
                          <div className="userName">
                            <Link to={`/User/${item.userUid}`}>
                              <h1>
                                {item?.fullName}{" "}
                                <span className="f-12 fw-100">
                                  {item?.userName}
                                </span>{" "}
                              </h1>
                            </Link>
                          </div>
                          <div className="designation">
                            <h1>{item?.title}</h1>
                          </div>
                          <div className="designation">
                            <h1>{item?.headLine}</h1>
                          </div>
                          <div className="location">
                            {item?.location && (
                              <>
                                <img src={locationIcon} alt="" />
                                <span className="locationText">
                                  {item?.location}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="vectorUserImage">
                            <img src={userAvatar} alt="" />
                            <span className="followerText ms-2">
                              {item?.followersCount} Followers
                            </span>
                          </div>
                        </Col>
                        <Col
                          md={3}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <Link
                            to={`/Message/${item?.userUid}`}
                            className="me-2"
                          >
                            <MailOutlineSharpIcon className="material-symbols-sharp" />
                          </Link>
                          <div
                            className="postMenu pointer"
                            onClick={() => handleToggleDropdown(index)}
                          >
                            <MoreHorizSharpIcon className="material-symbols-sharp" />

                            {showDropdown === index && (
                              <Dropdown.Menu
                                show={showDropdown === index}
                                ref={dropdownRef}
                                className="InteractionModal"
                              >
                                <Dropdown.Item
                                  as="button"
                                  className="dropdownList p-0"
                                >
                                  <Link onClick={() => handleShowModal(item)}>
                                    <div className="d-flex align-items-center">
                                      <ReportGmailerrorredSharpIcon
                                        className="material-symbols-sharp me-2" />
                                      <span className="dropdownTxt f-14">
                                        Disconnect
                                      </span>
                                    </div>
                                    <p className="f-12 dropdownPara">
                                      Disconnect User
                                    </p>
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            )}
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </div>
                );
              })
            ) : (
              searchconnectList &&
              searchconnectList?.map((item, index) => {
                console.log("mainconnection", item);
                return (
                  <div key={index}>
                    <Row>
                      <div className="resume-cont d-block d-md-flex">
                        <Col md={2} className="m-auto">
                          <Link to={`/User/${item.userUid}`}>
                            <div className="image-cont">
                              <img
                                src={item?.profilPic}
                                alt=""
                                width={70}
                                height={70}
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                          </Link>
                        </Col>
                        <Col md={7}>
                          <div className="userName">
                            <Link to={`/User/${item.userUid}`}>
                              <h1>
                                {item?.fullName}{" "}
                                <span className="f-12 fw-100">
                                  {item?.userName}
                                </span>{" "}
                              </h1>
                            </Link>
                          </div>
                          <div className="designation">
                            <h1>{item?.title}</h1>
                          </div>
                          <div className="designation">
                            <h1>{item?.headLine}</h1>
                          </div>
                          <div className="location">
                            {item?.location && (
                              <>
                                <img src={locationIcon} alt="" />
                                <span className="locationText">
                                  {item?.location}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="vectorUserImage">
                            <img src={userAvatar} alt="" />
                            <span className="followerText ms-2">
                              {item?.followersCount} Followers
                            </span>
                          </div>
                        </Col>
                        <Col
                          md={3}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <Link
                            to={`/Message/${item?.userUid}`}
                            className="me-2"
                          >
                            <MailOutlineSharpIcon className="material-symbols-sharp" />
                          </Link>
                          <div
                            className="postMenu pointer"
                            onClick={() => handleToggleDropdown(index)}
                          >
                            <MoreHorizSharpIcon className="material-symbols-sharp" />

                            {showDropdown === index && (
                              <Dropdown.Menu
                                show={showDropdown === index}
                                ref={dropdownRef}
                                className="InteractionModal"
                              >
                                <Dropdown.Item
                                  as="button"
                                  className="dropdownList p-0"
                                >
                                  <Link onClick={() => handleShowModal(item)}>
                                    <div className="d-flex align-items-center">
                                      <ReportGmailerrorredSharpIcon
                                        className="material-symbols-sharp me-2" />
                                      <span className="dropdownTxt f-14">
                                        Disconnect
                                      </span>
                                    </div>
                                    <p className="f-12 dropdownPara">
                                      Disconnect User
                                    </p>
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            )}
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </div>
                );
              })
            )}
          </>
        );
      case 2:
        return (
          <>
            {isLoading ? (
              <div className="mt-3">
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
              </div>
            ) : sentIsError ? (
              <div className=" error-connection">
                <img src={post.errorSmall} alt="404 Not Found" />
              </div>
            ) : (
              sentRequest &&
              sentRequest.map((item, index) => {
                return (
                  <Row key={index}>
                    <div className="resume-cont d-block d-md-flex">
                      <Col md={2} className="m-auto">
                        <Link to={`/User/${item.userUid}`} key={index}>
                          <div className="image-cont">
                            <img
                              src={item?.profilPic}
                              alt=""
                              width={70}
                              height={70}
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                        </Link>
                      </Col>

                      <Col md={7}>
                        <div className="userName">
                          <Link to={`/User/${item.userUid}`}>
                            <h1>
                              {item?.fullName}{" "}
                              <span className="f-12 fw-100">
                                {item?.userName}
                              </span>{" "}
                            </h1>
                          </Link>
                        </div>
                        <div className="designation">
                          <h1>{item?.title}</h1>
                        </div>
                        <div className="designation">
                          <h1>{item?.headLine}</h1>
                        </div>
                        <div className="location">
                          <div>
                            {item?.location && (
                              <>
                                <img src={locationIcon} alt="" />
                                <span className="locationText">
                                  {item?.location}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="vectorUserImage">
                          <img src={userAvatar} alt="" />
                          <span className="followerText ms-2">
                            {item?.followersCount} Followers
                          </span>
                        </div>
                      </Col>
                      <Col
                        md={3}
                        className="d-block d-md-flex flex-column align-items-end justify-content-center"
                      >
                        <button
                          type="submit"
                          className="wljSecondaryBtn"
                          onClick={() => {
                            setShow(!show);
                            setuserUID(item.userUid);
                          }}
                        >
                          Cancel
                        </button>
                      </Col>
                    </div>
                  </Row>
                );
              })
            )}
          </>
        );
      case 3:
        return (
          <>
            {isLoading ? (
              <div className="mt-3">
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
              </div>
            ) : receivedIsError ? (
              <div className=" error-connection">
                <img src={post.errorSmall} alt="404 Not Found" />
              </div>
            ) : (
              RecievedRequest &&
              RecievedRequest?.map((item, index) => {
                return (
                  <>
                    <Row key={index}>
                      <div className="resume-cont d-block d-md-flex">
                        <Col md={2} className="m-auto">
                          <Link to={`/User/${item.userUid}`} key={index}>
                            <div className="image-cont">
                              <img
                                src={item?.profilPic}
                                alt=""
                                width={70}
                                height={70}
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                          </Link>
                        </Col>

                        <Col md={7}>
                          <div className="userName">
                            <Link to={`/User/${item.userUid}`}>
                              <h1>
                                {item?.fullName}{" "}
                                <span className="f-12 fw-100">
                                  {item?.userName}
                                </span>{" "}
                              </h1>
                            </Link>
                          </div>
                          <div className="designation">
                            <h1>{item?.title}</h1>
                          </div>
                          <div className="designation">
                            <h1>{item?.headLine}</h1>
                          </div>
                          <div className="location">
                            <div>
                              {item?.location && (
                                <>
                                  <img src={locationIcon} alt="" />
                                  <span className="locationText">
                                    {item?.location}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="vectorUserImage">
                            <img src={userAvatar} alt="" />
                            <span className="followerText ms-2">
                              {item?.followersCount} Followers
                            </span>
                          </div>
                        </Col>
                        <Col
                          md={3}
                          className="d-flex d-md-flex align-items-center"
                        >
                          <button
                            type="submit"
                            className="wljPrimaryBtn me-3"
                            style={{ width: "70px" }}
                            onClick={() => {
                              AcceptRequest(item.userUid, item.cnctnId);
                            }}
                          >
                            Accept
                          </button>
                          <button
                            type="submit"
                            className="wljSecondaryBtn"
                            style={{ width: "70px" }}
                            onClick={() => {
                              RejectRequest(item.userUid, item.cnctnId);
                            }}
                          >
                            Reject
                          </button>
                        </Col>
                      </div>
                    </Row>
                  </>
                );
              })
            )}
          </>
        );
      case 4:
        return (
          <>
            {isLoading ? (
              <div className="mt-3">
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
                <ShimmerDiv
                  mode="light"
                  height={100}
                  width={"100%"}
                  className="mb-2"
                />
              </div>
            ) : recommendedIsError ? (
              <div className=" error-connection">
                <img src={post.errorSmall} alt="404 Not Found" />
              </div>
            ) : (
              recommendationList &&
              recommendationList?.map((item, index) => {
                console.log("recommendationListcon", item);
                return (
                  <>
                    <Row key={index}>
                      <div className="resume-cont d-block d-md-flex">
                        <Col md={2} className="m-auto">
                          <Link to={`/User/${item.userUid}`} key={index}>
                            <div className="image-cont">
                              <img
                                src={item?.profilPic}
                                alt=""
                                width={70}
                                height={70}
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                          </Link>
                        </Col>
                        <Col md={7}>
                          <div className="userName">
                            <Link to={`/User/${item.userUid}`}>
                              <h1>
                                {item?.fullName}{" "}
                                <span className="f-12 fw-100">
                                  {item?.userName}
                                </span>
                              </h1>
                            </Link>
                          </div>
                          <div className="designation">
                            <h1>{item?.title}</h1>
                          </div>
                          <div className="designation">
                            <h1>{item?.headLine}</h1>
                          </div>
                          <div className="location">
                            <div>
                              {item?.location && (
                                <>
                                  <img src={locationIcon} alt="" />
                                  <span className="locationText">
                                    {item?.location}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="vectorUserImage">
                            <img src={userAvatar} alt="" />
                            <span className="followerText ms-2">
                              {item?.followersCount} Followers
                            </span>
                          </div>
                          <div className="d-flex mt-1 ms-2">
                            <div className="circle-container">
                              {shortMutualList[item.userUid]?.pics?.map(
                                (pic, picIndex) => (
                                  <img
                                    src={pic}
                                    alt={`Circle ${picIndex + 1}`}
                                    className="circle-img"
                                    key={picIndex}
                                  />
                                )
                              )}
                            </div>
                            <div className={`f-12 text ${shortMutualList?.[item.userUid]?.count === 1 ? 'ms-2' : 'ms-4'}`} >
                              {shortMutualList[item.userUid]?.text}
                            </div>
                          </div>
                        </Col>
                        <Col
                          md={3}
                          className="d-flex d-md-flex align-items-center"
                        >
                          <RecommendationAction
                            item={item}
                            GetRecommendation={GetRecommendation}
                            setRecommendationList={setRecommendationList}
                            handleToggleDropdown={handleToggleDropdown}
                            index={index}
                            setShowDropdown={setShowDropdown}
                            showDropdown={showDropdown}
                          />
                        </Col>
                      </div>
                    </Row>
                  </>
                );
              })
            )}
          </>
        );
      default:
        return null;
    }
  };

  //************************On page scroll  ************************//

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
        setSentPage((prev) => prev + 1);
        setRecievePage((prev) => prev + 1);
        setRecommendedPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  console.log("location", location);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    if (show || showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show, showModal]);

  return (
    <>
      <div className="main-container connectionPage p-2">
        <Row className="mt-3">
          <Col md={12}>
            <div className="adImage">
              <img src={NewsFeed.AdImg} alt="advertisement" width={"100%"} />
            </div>
          </Col>
        </Row>
        <div className="advertisement m-auto mt-2">
          <div className="friendsHeaderText  pt-0 pb-0 mt-4 ">
            <h1>Connections</h1>
          </div>
        </div>

        <div className="tabButton d-flex p-3  w-100 mt-2 ps-0 connectionTab">
          {loading && <Loading />}
          <NavLink
            onClick={() => setActiveTab(1)}
            className={`text-center ${activeTab === 1 ? "activeTab" : ""}`}
          >
            <p className="f-14 tabName">Connections</p>
          </NavLink>
          <NavLink
            onClick={() => setActiveTab(2)}
            className={`text-center ${activeTab === 2 ? "activeTab" : ""}`}
          >
            <p className="f-14 tabName">Sent Request</p>
          </NavLink>
          <NavLink
            onClick={() => setActiveTab(3)}
            className={`text-center ${activeTab === 3 ? "activeTab" : ""}`}
          >
            <p className="f-14 tabName">Received Request</p>
          </NavLink>
          <NavLink
            onClick={() => setActiveTab(4)}
            className={`text-center ${activeTab === 4 ? "activeTab" : ""}`}
          >
            <p className="f-14 tabName">Recommendations</p>
          </NavLink>
        </div>

        <div className="resume-section">{renderTabContent()}</div>
      </div>
      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Cancel Request?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    cancelRequestClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    cancelRequestClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => CancelRequest()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>
                  Are you sure you want to Disconnect{" "}
                  <b>{selectedItem?.fullName}</b> ?
                </h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </div>

              <div className="text-end mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => handleConfirmAction()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default Connection;
