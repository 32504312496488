import React, { useEffect, useState } from "react";
import { ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";
import axios from "../../API/axios";
import { post } from "../../assets/Images/ImagesLink";
import AddPost from "./AddPost";
import PostInteractionModal from "./PostInteractionModal";
import { Link } from "react-router-dom";
import ImageSliderModal from "./ImageSliderModal";
import Loading from "../Blogs/Loading";
import moment from "moment";
import PostEngagement from "./PostEngagement";
import PostComment from "./PostComment";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import Comment from "./Comment";
import LikesDetails from "./LikesDetails";
import { warning } from "framer-motion";
import {
  ShimmerCategoryItems,
  ShimmerDiv,
  ShimmerTitle,
} from "shimmer-effects-react";
import pin from "../../assets/Images/svg/pin.svg";
import PostComponent from "./PostComponent";
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const AllPost = () => {
  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");
  const isDark = localStorage.getItem("isDark");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isError, setIsError] = useState(false);
  const [dLike, setDLike] = useState(0);

  const userUid = localStorage.getItem("userUid");
  const [nextBatchLoading, setNextBatchLoading] = useState("");

  const AllPostList = async (page) => {
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 12,
    };
    try {
      setLoading(true);
      setNextBatchLoading(true);
      const resData = await axios.post(
        `SMFeatures/NewsFeeds?SortBy=Recent `,
        data
      );
      setIsLoading(false);

      setTotalPages(resData.data.responseData.paging.totalPages);
      const newPosts = resData.data.responseData.postdetails;
      setPostList((prev) => {
        const postSet = new Set(prev.map((post) => post.postId));
        const uniqueNewPosts = newPosts.filter(
          (post) => !postSet.has(post.postId)
        );
        return [...prev, ...uniqueNewPosts];
      });
      setLoading(false);
      setNextBatchLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.error(error);
      }
      setLoading(false);
      setNextBatchLoading(false);
    }
  };

  // console.log("postLists===>", postList);
  useEffect(() => {
    AllPostList(page);
  }, [page]);

  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  const updateLikeCount = (postId, delta) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, likeCount: post.likeCount + delta }
          : post
      )
    );
  };

  const updateCommentCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, commentCount: post.commentCount + change }
          : post
      )
    );
  };

  const updateShareCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, shareCount: post.shareCount + change }
          : post
      )
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [singlePostContent, setSinglePostContent] = useState("");
  const [singlepostId, setSinglePostId] = useState("");
  const [ispostmodalOpen, setIspostmodalOpen] = useState(false);
  // const openModal = (images) => {
  //   setModalImages(images);
  //   setIsModalOpen(true);
  // };

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  });

  const [mediaFiles, setMediaFiles] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (mediaFiles, initialSlide) => {
    setSliderSettings((prevSettings) => ({
      ...prevSettings,
      initialSlide: initialSlide,
    }));
    setMediaFiles(mediaFiles);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isfollowed, setIsfollowed] = useState(false);

  const [emojiList, setemojiList] = useState();
  // console.log('emojiList', emojiList);
  const GetEmoji = async () => {
    try {
      await axios.get("Master/GetEmojiDDL").then((res) => {
        // console.log("share", res);
        setemojiList(res.data.responseData);
        // updateShareCount(postId, 1);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetEmoji();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [postLikedType, setPostLikedType] = useState(false);
  const [postId, setPostId] = useState("");
  // const [initialSlideIndex, setInitialSlideIndex] = useState("");
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);
  const [imageArray, setImageArray] = useState([]);

  const handleShowModal = (item) => {
    setPostId(item.postId);
    setPostLikedType(item.likedType);
    setShowModal(true);
  };
  const handleLikesCloseModal = () => {
    setShowModal(false);
    // setSelectedItem(null);
  };
  // const [fullContent, setFullContent] = useState('')
  // const readMore = async (id) => {
  //   try {
  //     await axios.get(`/Posts/GetPostMainContent/?DeviceType=Web&PostId=${id}`).then((res) => {
  //       console.log('ressss', res);
  //       setFullContent(res.data.responseData)
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    scrollToTop();
  }, []);

  const [showDeletePost, setShowDeletePost] = useState(false)

  useEffect(() => {
    if (isModalOpen || showDeletePost) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModalOpen, showDeletePost])
  // const [userPer, setUserPer] = useState([]);
  // const [alert, setAlert] = useState(false);
  // const getUserProfilePer = () => {
  //   const data = {
  //     deviceType: "Web",
  //     sessionId: sessionId,
  //   };
  //   axios
  //     .post(`UserProfile/GetProfilePercentage`, data)
  //     .then((response) => {
  //       // console.log("userprofilestat", response.data.responseData);
  //       const res = response.data.responseData;
  //       setUserPer(res.percentage);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // useEffect(() => {
  //   getUserProfilePer();
  // }, [sessionId]);
  // // Normalize userPer to a number, defaulting to 0 if it is an empty array or non-numeric
  // const normalizedUserPer = Array.isArray(userPer) ? userPer.length === 0 ? 0 : Number(userPer[0]) : Number(userPer);

  // // Condition to show the alert
  // const shouldShowAlert =
  //   normalizedUserPer <= 59 &&
  //   alert === false &&
  //   (guestModeData === null);



  const [userPer, setUserPer] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isLoadingalert, setIsLoadingalert] = useState(true); // Add loading state

  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        const res = response.data.responseData;
        setUserPer(res.percentage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingalert(false); // Set loading to false once the data is fetched
      });
  };

  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  // Normalize userPer to a number, defaulting to 0 if it is an empty array or non-numeric
  const normalizedUserPer = Array.isArray(userPer) ? (userPer.length === 0 ? 0 : Number(userPer[0])) : Number(userPer);

  // Condition to show the alert
  const shouldShowAlert = !isLoadingalert && normalizedUserPer <= 59 && alert === false && guestModeData === null;



  return (
    <>
      {/* {shouldShowAlert && (
        <div className='p-3 closeAccount m-0 mb-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon
                style={{ width: '20px' }}
                className="pointer"
                onClick={() => setAlert(true)} // Dismiss the alert
              />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
          </div>
          <div className="mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}
              >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>
      )} */}

      {shouldShowAlert && (
        <div className='p-3 closeAccount m-0 mb-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon
                style={{ width: '20px' }}
                className="pointer"
                onClick={() => setAlert(true)} // Dismiss the alert
              />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
          </div>
          <div className="mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}
              >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>
      )}

      <AddPost
        AllPostList={AllPostList}
        PostOriginType={"User"}
        postOriginId={userUid}
        setPostList={setPostList}
      />

      {/* <ShimmerSocialPost height={250} rounded width={"50px"} /> */}
      {isLoading ? (
        <div className="mt-3">
          <div className="shimmer-cont">
            <div className="shimmer-header d-flex">
              <ShimmerDiv mode="light" height={60} width={60} rounded={50} />
              <div className="shimmer-title ms-3">
                <ShimmerTitle mode="light" line={2} gap={8} />
              </div>
            </div>
            <div className="square-shimmer mt-2">
              <ShimmerDiv mode="light" height={100} width={100} />
            </div>
            {/* <ShimmerCategoryItems mode="light" imageRounded={50} /> */}
          </div>

          {/* <ShimmerSocialPost height={250} rounded width={"50px"} /> */}
        </div>
      ) : isError ? (
        <div className="mt-2 error-image-cont">
          <img src={post.errorbig} alt="404 Not Found" />
        </div>
      ) : (
        postList &&
        postList
          .filter((item) => !item.isDeleted)
          ?.map((item, index) => {
            console.log("itemmmmm", item);
            return (
              <div
                className="featured-card user-postOnlyContent p-2 mb-3"
                key={index}
              >
                <div className="feature-content p-2 d-flex justify-content-between">
                  <Link
                    to={
                      item?.postOriginType === "Company"
                        ? `/Company/${item?.userUid}`
                        : `/User/${item.userUid}`
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div className="ImgAvatar">
                        <img
                          src={item?.userAvatar}
                          alt=""
                          style={{ borderRadius: "50px" }}
                        />
                      </div>
                      {/* {console.log('item.creationTime', item.postContent, item.creationTime)} */}

                      <div className="ms-2">
                        <h4 className="f-14 fw-bold mb-0">{item.userName} </h4>
                        <p className="f-12">{item?.title}</p>
                        <p className="f-12">{item?.additionalInfo}</p>
                        <p className="f-10 label">
                          {/* {moment(item.creationTime).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )} */}
                          {moment
                            .utc(item.creationTime)
                            .local()
                            .format("DD MMMM YYYY [at] h:mm a")}
                        </p>
                      </div>
                    </div>
                  </Link>

                  {guestModeData === "true" ? (
                    ""
                  ) : (
                    <div className="row">
                      <div>
                        <PostInteractionModal
                          postId={item?.postId}
                          AllPostList={AllPostList}
                          postList={postList}
                          setPage={setPage}
                          setPostList={setPostList}
                          page={page}
                          setTotalPages={setTotalPages}
                          totalPages={totalPages}
                          item={item}
                          PostOriginType={"User"}
                          postOriginId={userUid}
                          isfollowed={isfollowed}
                          setIsfollowed={setIsfollowed}
                          setShowDeletePost={setShowDeletePost}
                          showDeletePost={showDeletePost}
                          ispinned={item?.isPinned}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* {item?.postContent && (
                  <p className="f-14 p-2 postContent">{fullContent === '' ? item?.postContent : fullContent}

                    {item?.readMore === true ? <a onClick={() => readMore(item?.postId)}>Read More</a> : ''}
                  </p>
                )} */}
                <PostComponent item={item} />

                <div
                  className="feed-post"
                  style={{
                    paddingTop: item?.postMediaCount === 0 ? "60px" : "4px",
                  }}
                >
                  <div className="row">
                    {item.mediaFilesList.slice(0, 4).map((file, idx) => (
                      <div
                        key={idx}
                        className={`col-${item.mediaFilesList.length === 1 ? "12" : "6"
                          } ${item.mediaFilesList.length === 3 && idx === 0
                            ? "col-12"
                            : ""
                          }`}
                        onClick={() => {
                          setInitialSlideIndex(idx);
                          setImageArray(item.mediaFilesList);
                          console.warn("===========1============>>", idx);
                          let imageData = item.mediaFilesList.map(
                            (file) => file.mediaName
                          );
                          openModal(imageData, idx);
                          setSinglePostContent(item);
                          setSinglePostId(item?.postId);
                        }}
                        style={{
                          position: "relative",
                          padding:
                            item.mediaFilesList.length === 3 && idx === 0
                              ? "0 0 5px 0"
                              : item.mediaFilesList.length === 3 && idx === 1
                                ? "0 5px 5px 0"
                                : item.mediaFilesList.length === 4 && idx === 2
                                  ? "0 5px 0 0"
                                  : item.mediaFilesList.length === 4 && idx === 0
                                    ? "0 5px 5px 0"
                                    : item.mediaFilesList.length === 2 && idx === 0
                                      ? "0 5px 5px 0"
                                      : "",
                        }}
                      >
                        <img
                          src={file.mediaName}
                          alt=""
                          className="img-fluid"
                          width={"100%"}
                        />
                        {idx === 3 && item.postMediaCount > 4 && (
                          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50 text-white">
                            +{item.postMediaCount - 4}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {guestModeData === "true" ? (
                    ""
                  ) : (
                    <div className="row comment-gradient">
                      <div
                        className="addComment"
                        // onClick={() => {
                        //   openModal(
                        //     item.mediaFilesList.map((file) => file.mediaName),
                        //     0
                        //   );
                        //   setSinglePostContent(item);
                        //   setSinglePostId(item?.postId);
                        // }}
                        onClick={() => {
                          setInitialSlideIndex(1);
                          setImageArray(item.mediaFilesList);
                          let imageData = item.mediaFilesList.map(
                            (file) => file.mediaName
                          );
                          openModal(imageData, 1);
                          setSinglePostContent(item);
                          setSinglePostId(item?.postId);
                        }}
                      >
                        <div
                          className="postCommentWrapper"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <PostComment
                            item={item}
                            postId={item?.postId}
                            AllPostList={AllPostList}
                            postList={postList}
                            setPage={setPage}
                            setPostList={setPostList}
                            page={page}
                            setTotalPages={setTotalPages}
                            totalPages={totalPages}
                            updateCommentCount={updateCommentCount}
                            ispostmodalOpen={ispostmodalOpen}
                            setIspostmodalOpen={setIspostmodalOpen}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`postData d-flex align-items-center ${item?.likeCount === 0 && item?.commentCount === 0 && item?.shareCount === 0
                  ? ""
                  : "border-custom1"
                  }`}>
                  <div className="ms-2">
                    {item?.likeCount === 0 ? (
                      ""
                    ) : (
                      <p
                        className="f-10 like-details"
                        onClick={() => handleShowModal(item)}
                      >
                        {item?.likeCount + dLike} likes
                      </p>
                    )}
                  </div>
                  <div className="ms-auto d-flex">
                    {item?.commentCount === 0 ? (
                      ""
                    ) : (
                      <p
                        className="f-10 me-2 pointer"
                        onClick={() => {
                          openModal(
                            item.mediaFilesList.map((file) => file.mediaName)
                          );
                          setSinglePostContent(item);
                          setSinglePostId(item?.postId);
                        }}
                      >
                        {" "}
                        {item?.commentCount} comments{" "}
                      </p>
                    )}

                    <p className="f-10">
                      {item?.shareCount === 0
                        ? ""
                        : `&  ${item?.shareCount} Shares`}
                    </p>
                  </div>
                </div>
                <div className="postengagement">
                  <PostEngagement
                    item={item}
                    postId={item?.postId}
                    AllPostList={AllPostList}
                    postList={postList}
                    setPage={setPage}
                    setPostList={setPostList}
                    page={page}
                    setTotalPages={setTotalPages}
                    totalPages={totalPages}
                    updateLikeCount={updateLikeCount}
                    updateShareCount={updateShareCount}
                    openModal={openModal}
                    setSinglePostContent={setSinglePostContent}
                    setSinglePostId={setSinglePostId}
                    ispostmodalOpen={ispostmodalOpen}
                    setIspostmodalOpen={setIspostmodalOpen}
                    emojiList={emojiList}
                  />
                </div>
                <Comment
                  item={item}
                  isOwnedPost={item?.isOwned}
                  postId={item?.postId}
                  AllPostList={AllPostList}
                  postList={postList}
                  setPage={setPage}
                  setPostList={setPostList}
                  page={page}
                  setTotalPages={setTotalPages}
                  totalPages={totalPages}
                  emojiList={emojiList}
                />
              </div>
            );
          })
      )}
      {nextBatchLoading && (
        <div className="text-center">
          <img src={post?.loader} width={50} />
        </div>
      )}

      {console.log('isModalOpen', isModalOpen)}


      {isModalOpen && (
        <ImageSliderModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          images={modalImages}
          item={singlePostContent}
          singlepostId={singlepostId}
          setSinglePostId={setSinglePostId}
          updateShareCount={updateShareCount}
          updateLikeCount={updateLikeCount}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          page={page}
          setPostList={setPostList}
          setPage={setPage}
          postList={postList}
          AllPostList={AllPostList}
          PostEngagement={PostEngagement}
          dLike={dLike}
          updateCommentCount={updateCommentCount}
          ispostmodalOpen={ispostmodalOpen}
          setIspostmodalOpen={setIspostmodalOpen}
          openModal={openModal}
          setSinglePostContent={setSinglePostContent}
          setDLike={setDLike}
          setShowModal={showModal}
          handleLikesCloseModal={handleLikesCloseModal}
          isfollowed={isfollowed}
          setIsfollowed={setIsfollowed}
          initialSlideIndex={initialSlideIndex}
          setInitialSlideIndex={setInitialSlideIndex}
          imageArray={imageArray}
        />
      )}
      {/* {showModal &&  */}
      <LikesDetails
        showModal={showModal}
        handleLikesCloseModal={handleLikesCloseModal}
        postId={postId}
        postLikedType={postLikedType}
      />
      {/* } */}


      {loading && (
        <div className="row">
          <div className="col-md-12">
            <Loading />
          </div>
        </div>
      )}
      {showToast && (
        <SuccessToast
          show={showToast}
          message={toastMessage}
          onClose={() => setShowToast(false)}
        />
      )}

      {showWarningToast && (
        <WarningToast
          show={showWarningToast}
          message={warningToastMessage}
          onClose={() => setShowWarningToast(false)}
        />
      )}
      {showErrorToast && (
        <ErrorToast
          show={showErrorToast}
          message={ErrorToastMessage}
          onClose={() => setShowErrorToast(false)}
        />
      )}
    </>
  );
};

export default AllPost;
