import React, { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
// import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Form, Input, } from "reactstrap";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr'
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
// import {
//   Button,
//   Col,
//   Form,
//   FormFeedback,
//   Input,
//   Label,
//   Modal,
//   ModalBody,
//   ModalHeader,
//   Row,
// } from "reactstrap";

const ProfileEducation = ({
  showUSEREducation,
  handleCloseUSEREducation,
  userUId,
  getUserCcv,
  getUserProfile,
}) => {
  //********Location DDL Start  ********//
  const [value, setValue] = useState(null);
  const [page, setPage] = useState(0);
  const [typed, setTyped] = useState("");
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [display, setDisplay] = useState(false);

  const [error, setError] = useState("");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [checkCurrent, setCheckCurrent] = useState('')
  console.log('checkCurrent', checkCurrent);

  const CheckCurrentEducationStatus = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(`UserData/CheckCurrentEducationStatus`, responseData)
        .then((res) => {
          setCheckCurrent(res?.data?.responseData)
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  useEffect(() => {
    CheckCurrentEducationStatus()
  }, [])

  // console.log('value', value);
  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);
  // console.log('typed', typed);
  const addOptions = () => {
    axios
      .get(
        `Master/GetLocalitiesDDL?search=${typed}&pageNumber=${page + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log("edu", response);
        const data = response.data.responseData.localityList.map((item) => ({
          value: item.locationId,
          label: item.locationName,
        }));
        setPage((prevPage) => prevPage + 1);
        setOptions((prevOptions) => [...prevOptions, ...data]);
        setTotalPages(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpen = () => {
    setPage(1);
  };

  const handleChange = (val) => {
    setValue(val.value);
    setPage(0);
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPage(0);
  };

  const handleMenuScrollToBottom = () => {
    if (page < totalPages) {
      addOptions();
    }
  };

  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typed) {
      addOptions();
    }
  }, [typed]);

  //********Location DDL End  ********//

  //********Institution DDL Start  ********//
  const [valueIns, setValueIns] = useState(null);
  const [pageIns, setPageIns] = useState(0);
  const [typedIns, setTypedIns] = useState("");
  const [optionsIns, setOptionsIns] = useState([]);
  const [totalPagesIns, setTotalPagesIns] = useState(0);
  // console.log('value', value);
  const logValueIns = debounce((typedIns) => {
    setTypedIns(typedIns);
  }, 250);
  // console.log('typed', typed);
  // const addOptionsIns = () => {
  //   axios
  //     .get(
  //       `Master/GetAllInstitutionsDDL?search=${typedIns}&pageNumber=${pageIns + 1
  //       }&pageSize=100&DeviceType=Web`
  //     )
  //     .then((response) => {
  //       console.log(response.data.responseData.orgList);
  //       const data = response.data.responseData.list.map((item) => ({
  //         value: item.uiDno,
  //         label: item.name,
  //         isOrgVrfd: item.isVerified,
  //         isOrgRgd: item.isRgstrd,
  //       }));
  //       setPageIns((prevPage) => prevPage + 1);
  //       setOptionsIns((prevOptions) => [...prevOptions, ...data]);
  //       setTotalPagesIns(response.data.responseData.paging.totalPages);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const addOptionsIns = () => {
    axios
      .get(
        `Master/GetAllInstitutionsDDL?search=${typedIns}&pageNumber=${pageIns + 1
        }&pageSize=100&DeviceType=Web`
      )
      .then((response) => {
        console.log(response.data.responseData.orgList);
        const newOptions = response.data.responseData.list.map((item) => ({
          value: item.uiDno,
          label: item.name,
          isOrgVrfd: item.isVerified,
          isOrgRgd: item.isRgstrd,
        }));

        // Filter out duplicates based on the institution name (label) or id (value)
        // setOptionsIns((prevOptions) => {
        //   const mergedOptions = [...prevOptions, ...newOptions];
        //   const uniqueOptions = mergedOptions.filter(
        //     (option, index, self) =>
        //       index === self.findIndex((o) => o.value === option.value)
        //   );
        //   return uniqueOptions;
        // });
        setOptionsIns((prevOptions) => {
          const mergedOptions = [...prevOptions, ...newOptions];
          const uniqueOptions = mergedOptions.filter(
            (option, index, self) =>
              index === self.findIndex((o) => o.value === option.value)
          );
          return uniqueOptions;
        });




        setPageIns((prevPage) => prevPage + 1);
        setTotalPagesIns(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleOpenIns = () => {
    setPageIns(1);
  };

  const handleChangeIns = (valueIns) => {
    if (valueIns === null) {
      setValueIns(null);
    } else {
      setValueIns(valueIns.value);
    }
    setPageIns(0);
  };

  const handleInputChangeIns = (typedIns) => {
    logValueIns(typedIns);
    if (!typedIns) setPageIns(0);
  };

  const handleMenuScrollToBottomIns = () => {
    if (pageIns < totalPagesIns) {
      addOptionsIns();
    }
  };

  const renderOptionIns = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typedIns) {
      addOptionsIns();
    }
  }, [typedIns]);

  //********Institution DDL End  ********//

  //********Specialization DDL Start  ********//
  const [specData, setSpecData] = useState([]);
  const [specId, setSpecId] = useState(null);
  const getSpecialization = () => {
    axios
      .get(`Master/GetSpecializationsDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.spclznId,
          label: item.name,
        }));
        setSpecData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getSpecialization();
  }, []);
  //********Specialization DDL End  ********//

  //********Qualification DDL Start  ********//
  const [qualiData, setQualiData] = useState([]);
  const [qualiId, setQualiId] = useState(null);
  const getQualification = () => {
    axios
      .get(`Master/GetQualificationsDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.qlfctnId,
          label: item.name,
        }));
        setQualiData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getQualification();
  }, []);
  //********Qualification DDL End  ********//

  const sessionId = localStorage.getItem("sessionId");
  const [startDate, setStartDate] = useState("");
  const [field, setField] = useState("");
  const [grade, setGrade] = useState("");
  const [degree, setDegree] = useState("");
  const [activities, setActivities] = useState("");
  const [present, setPresent] = useState(false);
  const [endDate, setEndDate] = useState("");

  //********City DDL Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState("");
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);
  //********City DDL End  ********//

  const reset = () => {
    setCityId([]);
    setStartDate("");
    setEndDate("");
    setPresent(false);
    setValue(null);
    setValueIns(null);
    setDescription("");
    setQualiId("");
    setSpecId("");
    setField("");
    setDegree("");
    setGrade("");
    setActivities("");
  };

  // const [DropdownValidation, setDropdownValidation] = useState(null);

  // const [errors, setErrors] = useState({});
  console.log('education data', startDate, endDate);

  //********Add Education Start  ********//
  const AddEducation = async (event) => {
    setDisplay(true);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      cityId: cityId,
      startYear: startDate === "" ? "" : moment(startDate).format("YYYY"),
      endYear: endDate === "" ? "" : moment(endDate).format("YYYY"),
      isPresent: present,
      locationId: null,
      startMonth: startDate === "" ? "" : moment(startDate).format("MM"),
      endMonth: endDate === "" ? "" : moment(endDate).format("MM"),
      description: description,
      institutionUid: valueIns,

      field: field,
      degree: degree,
      grade: grade,
      activities: activities,
    };
    console.log("education data", data);
    if (valueIns === null || cityId === null || field === '' || degree === '' || startDate === '') {
      setErrorToastMessage('Please fill all the required fields');
      setShowErrorToast(true);
    } else {
      try {
        await axios.post(`UserData/AddEducationInfo`, data).then((resData) => {
          console.log("resData", resData);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          getUserProfile();
          handleCloseUSEREducation();
          reset();
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }

    }
  };

  //********Add Education End  ********//

  //********Create Education Start  ********//
  const [CreateCCVEducation, setCreateCCVEducation] = useState(false);
  const handleOpenCreateEducation = () => setCreateCCVEducation(true);
  const handleCloseCreateEducation = () => setCreateCCVEducation(false);

  const [institutionName, setInstitutionName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [description, setDescription] = useState("");
  const [publicUrl, setPublicUrl] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [about, setAbout] = useState("");

  const CreateEducation = async () => {
    setDisplay(true);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      insttnName: institutionName,
      cityId: cityId,
      startYear: startDate === "" ? "" : moment(startDate).format("YYYY"),
      endYear: endDate === "" ? "" : moment(endDate).format("YYYY"),
      startMonth: startDate === "" ? "" : moment(startDate).format("MM"),
      endMonth: endDate === "" ? "" : moment(endDate).format("MM"),
      isPresent: present,
      locationId: null,
      email: email,
      contactNo: phoneNo,
      publicUrl: publicUrl,
      website: website,
      tagline: tagline,
      about: about,
      description: description,
      // qualificationId: qualiId,
      // specilizationId: specId,
      field: field,
      degree: degree,
      grade: grade,
      activities: activities,

      startedDate: "",
      logoImage: "",
      backgroudImage: "",
      facebook: "",
      twitter: "",
      instagram: "",
    };
    if (institutionName === '' || cityId === null || field === '' || degree === '' || startDate === '' || endDate === '') {
      setErrorToastMessage('Please fill all the required fields');
      setShowErrorToast(true);
    } else {
      try {
        await axios
          .post(`UserData/CreateAndAddEducationInfo`, data)
          .then((resData) => {
            console.log("resData", resData.data.responseData);
            //   toast.success(resData.data.message);
            setToastMessage(resData.data.message);
            setShowToast(true);
            getUserProfile();
            handleCloseCreateEducation();
            handleCloseUSEREducation();
            reset()
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };

  //********Create Education End  ********//

  const handleInputChanges = (e) => {
    setDegree(e.target.value);
    if (e.target.value.trim() === "") {
      setError("Degree is required");
    } else {
      setError("");
    }
  };

  const [values, setValueInput] = useState({
    institute: "",
  });
  //   const [ valueInput , setValueInput ] = useState()
  function handleChangeInput(e) {
    const newObj = { ...values, [e.target.name]: e.target.value };
    setValueInput(newObj);
  }

  useEffect(() => {
    if (showUSEREducation || CreateCCVEducation) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showUSEREducation, CreateCCVEducation]);

  console.log('present', present);

  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };

  return (
    <>
      {showUSEREducation && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Add Education</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-3 pointer"
                  onClick={handleCloseUSEREducation}
                />
              </div>
              <Form>
                <div className="row modal-p settings CreateCompany">
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about" for="institute">
                      Institution<span className="text-danger ms-2">*</span>
                    </label>

                    <CreatableSelect
                      defaultValue={valueIns}
                      options={optionsIns}
                      optionRenderer={renderOptionIns}
                      onOpen={handleOpenIns}
                      onChange={handleChangeIns}
                      onMenuScrollToBottom={handleMenuScrollToBottomIns}
                      onInputChange={handleInputChangeIns}
                      isClearable
                      onCreateOption={(newOption) => {
                        handleOpenCreateEducation();
                        handleCloseUSEREducation();
                      }}
                      invalid={display && valueIns === ""}
                      placeholder={"Search and Select Institution"}
                      className="iconsDropdown"
                      styles={customStyles}
                      formatOptionLabel={(option) => (
                        <div>
                          {option.label}
                          {option.isOrgVrfd === true ? (
                            <img src={resume.verified} width={15} className="ms-1" />
                          ) : option.isOrgRgd === true ? (
                            <img src={resume.register} width={15} className="ms-1" />
                          ) : (
                            <img src={resume.unregister} width={15} className="ms-1" />
                          )}
                        </div>
                      )}
                    />
                    {display && !valueIns ? (
                      <span className="error_msg_lbl">
                        Please Select Institution{" "}
                      </span>
                    ) : null}
                  </div>

                  {/* <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Specialization
                    </label>
                    <Select
                      defaultValue={specId}
                      onChange={(selectedOption) =>
                        setSpecId(selectedOption.value)
                      }
                      options={specData}
                      invalid={display && specId === ""}
                      placeholder={" Select Specialization"}
                      styles={customStyles}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Qualification
                    </label>
                    <Select
                      defaultValue={qualiId}
                      onChange={(selectedOption) =>
                        setQualiId(selectedOption.value)
                      }
                      invalid={display && qualiId === ""}
                      options={qualiData}
                      placeholder={" Select Qualification"}
                      styles={customStyles}
                    />
                  </div> */}

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      City<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      defaultValue={cityId}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);

                      }}
                      invalid={display && cityId === ""}
                      styles={customStyles}
                    />
                    {display && !cityId ? (
                      <span className="error_msg_lbl">Select City </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Field<span className="text-danger ms-2">*</span>
                    </label>
                    <Input
                      type="text"
                      value={field}
                      // placeholder="Enter field"
                      onChange={(e) => setField(e.target.value)}
                      invalid={display && field === ""}
                    />
                    {display && !field ? (
                      <span className="error_msg_lbl">Enter Field </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Degree<span className="text-danger ms-2">*</span>
                    </label>
                    <Input
                      type="text"
                      value={degree}
                      onChange={(e) => setDegree(e.target.value)}
                      invalid={display && degree === ""}
                    //   onChange={handleInputChanges}
                    />
                    {display && !degree ? (
                      <span className="error_msg_lbl">Enter Degree </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Grade
                    </label>
                    <Input
                      type="text"
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Activities
                    </label>
                    <Input
                      type="text"
                      value={activities}
                      onChange={(e) => setActivities(e.target.value)}
                    />
                  </div>
                  {checkCurrent === false ? <div className="col-md-12 mt-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      style={{ width: "40px" }}
                      //   id={`check-${item.id}`}
                      //   checked={selectedWorkLevelList.includes(item.id)}
                      onChange={() => setPresent(!present)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> : ''}


                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Start Year<span className="text-danger ms-2">*</span>
                    </label>
                    {/* <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      invalid={display && startDate === ""}
                    /> */}
                    <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob' options={{
                      maxDate: "today", // Disables future dates
                      onReady: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Call the function to stop scroll on year
                      },
                      onOpen: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                      },
                    }} />
                    {display && !startDate ? (
                      <span className="error_msg_lbl">
                        Please Select Start Year{" "}
                      </span>
                    ) : null}
                  </div>

                  {present === true ? '' : <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      End Year
                    </label>
                    {/* <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      invalid={display && endDate === ""}
                    /> */}
                    <Flatpickr className='form-control' value={endDate} onChange={date => setEndDate(date[0])} id='dob' options={{
                      maxDate: "today", // Disables future dates
                      onReady: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Call the function to stop scroll on year
                      },
                      onOpen: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                      },
                    }} />
                    {/* {display && !endDate ? (
                      <span className="error_msg_lbl">
                        Please Select End Year{" "}
                      </span>
                    ) : null} */}
                  </div>}


                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Description
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="2"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>

                  {/* <div className="col-md-12 mt-2 d-flex ">
                    <Input
                      type="checkbox"
                      style={{ width: "40px" }}
                      onChange={() => setPresent(true)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> */}



                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="closebtn me-2"
                      onClick={handleCloseUSEREducation}
                      style={{ width: "60px" }}
                    >
                      close
                    </button>

                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={AddEducation}
                      style={{ width: "70px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
      {CreateCCVEducation && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Create Education</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={handleCloseCreateEducation}
                />
              </div>
              <Form>
                <div className="row p-4 settings CreateCompany">
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Institution Name
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Input
                      type="text"
                      value={institutionName}
                      onChange={(e) => setInstitutionName(e.target.value)}
                      invalid={display && institutionName === ""}
                    />
                    {display && !institutionName ? (
                      <span className="error_msg_lbl">
                        Enter Institution Name{" "}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      City<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      defaultValue={cityId}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      invalid={display && cityId === ""}
                      styles={customStyles}
                    />
                    {display && !cityId ? (
                      <span className="error_msg_lbl">Please Select City </span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Email
                    </label>
                    <Input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Phone No
                    </label>
                    <Input
                      type="text"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Public URL
                    </label>
                    <Input
                      type="text"
                      value={publicUrl}
                      onChange={(e) => setPublicUrl(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Website
                    </label>
                    <Input
                      type="text"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      TagLine
                    </label>
                    <Input
                      type="text"
                      value={tagline}
                      onChange={(e) => setTagline(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      About
                    </label>
                    <Input
                      type="text"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    />
                  </div>



                  {/* <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Specialization
                    </label>

                    <Select
                      defaultValue={specId}
                      onChange={(selectedOption) =>
                        setSpecId(selectedOption.value)
                      }
                      options={specData}
                      placeholder={" Select Specialization"}
                      invalid={display && specId === ""}
                      styles={customStyles}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      qualificationId
                    </label>
                    <Select
                      defaultValue={qualiId}
                      onChange={(selectedOption) =>
                        setQualiId(selectedOption.value)
                      }
                      options={qualiData}
                      placeholder={" Select Qualification"}
                      invalid={display && qualiId === ""}
                      styles={customStyles}
                    />
                  </div> */}



                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Field<span className="text-danger ms-2">*</span>
                    </label>
                    <Input
                      type="text"
                      value={field}
                      onChange={(e) => setField(e.target.value)}
                      invalid={display && field === ""}
                    />
                    {display && !field ? (
                      <span className="error_msg_lbl">Enter Field </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Degree<span className="text-danger ms-2">*</span>
                    </label>
                    <Input
                      type="text"
                      value={degree}
                      onChange={(e) => setDegree(e.target.value)}
                      invalid={display && degree === ""}
                    />
                    {display && !degree ? (
                      <span className="error_msg_lbl">Enter Degree </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Grade
                    </label>
                    <Input
                      type="text"
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Activities
                    </label>
                    <Input
                      type="text"
                      value={activities}
                      onChange={(e) => setActivities(e.target.value)}
                    />
                  </div>
                  {checkCurrent === false ? <div className="col-md-12 mt-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      style={{ width: "40px" }}
                      //   id={`check-${item.id}`}
                      //   checked={selectedWorkLevelList.includes(item.id)}
                      onChange={() => setPresent(!present)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> : ''}

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Start Year<span className="text-danger ms-2">*</span>
                    </label>
                    {/* <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      invalid={display && startDate === ""}
                    /> */}
                    <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob' options={{
                      maxDate: "today", // Disables future dates
                      onReady: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Call the function to stop scroll on year
                      },
                      onOpen: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                      },
                    }} />
                    {display && !startDate ? (
                      <span className="error_msg_lbl">
                        Please Select Start Year{" "}
                      </span>
                    ) : null}
                  </div>

                  {present === true ? '' : <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      End Year
                    </label>
                    {/* <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      invalid={display && endDate === ""}
                    /> */}
                    <Flatpickr className='form-control' value={endDate} onChange={date => setEndDate(date[0])} id='dob' options={{
                      maxDate: "today", // Disables future dates
                      onReady: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Call the function to stop scroll on year
                      },
                      onOpen: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                      },
                    }} />
                    {/* {display && !endDate ? (
                      <span className="error_msg_lbl">
                        Please Select End Year{" "}
                      </span>
                    ) : null} */}
                  </div>}

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Description
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="4"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>

                  {/* <div className="col-md-12 mt-2 d-flex ">
                    <Input
                      type="checkbox"
                      style={{ width: "40px" }}
                      //   id={`check-${item.id}`}
                      //   checked={selectedWorkLevelList.includes(item.id)}
                      onChange={() => setPresent(true)}
                    />
                    <label htmlFor="about">IsPresent</label>
                  </div> */}

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="mainBtn1"
                      style={{ width: "170px" }}
                      onClick={CreateEducation}
                    >
                      Create Education
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}


      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default ProfileEducation;
