import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/Business.css";
import axios from "../../API/axios";
import { business, post } from "../../assets/Images/ImagesLink";
import { Col, Dropdown, NavLink, Row } from "react-bootstrap";
import Followers from "../../Views/BusinessPage/Followers";
import Jobs from "../../Views/BusinessPage/Jobs";
import Posts from "../../Views/BusinessPage/Posts";
import AboutCompany from "./AboutCompany";
import { Link, useParams } from "react-router-dom";
import ModalForGuest from "../ModalForGuest";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import Reviews from "./Reviews";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { userAuthImage } from "../../assets/Images/ImagesLink";
import { ShimmerDiv } from "shimmer-effects-react";
import CompanyActions from "../MyCompanies/CompanyActions";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const BusinessPage = () => {
  // Local states

  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");
  const getUserID = localStorage.getItem("userUid");
  const { CompUid } = useParams();

  //   States for Review Modal
  const [showReviewModal, setShowReviewModal] = useState(false);
  const handleCloseReviewModal = () => setShowReviewModal(false);
  const handleShowReview = () => setShowReviewModal(true);
  const [reviewContent, setReviewContent] = useState("");

  //   Toaster's State
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [companyDetails, setCompanyDetails] = useState("");

  const CompanyDetails = async () => {
    setIsLoading(true);
    try {
      const result = await axios.post(`Company/GetCompanyDetails`, {
        deviceType: "Web",
        compUid: CompUid,
      });
      console.log('hgjhadgs', result);
      if (result?.status === 204) {
        setCompanyError(true)
      } else {
        const Response = result?.data?.responseData;
        setCompanyDetails(Response);
        setOwnedCompanyDetails(Response?.isOwned);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    CompanyDetails();
  }, [CompUid]);
  //   For Tab
  const [activeTab, setActiveTab] = useState(1);
  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return <Posts CompUid={CompUid} companyDetails={companyDetails} />;
      case 2:
        return <Jobs CompUid={CompUid} companyDetails={companyDetails} />;
      case 3:
        return <Followers CompUid={CompUid} />;
      case 4:
        return <Reviews CompUid={CompUid} companyDetails={companyDetails} CompanyDetails={CompanyDetails} setJobModal={setJobModal} handleShowReview={handleShowReview} CompanyStatsCount={CompanyStatsCount} />;
      default:
        return null;
    }
  };

  //   Get company details

  const [ownedCompanyDetails, setOwnedCompanyDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);



  const [jobModal, setJobModal] = useState(false);
  const closeModalData = () => {
    setJobModal(false);
  };

  //   Company job alert check
  const [jobAlert, setJobAlert] = useState("");
  const [makeJobAlertConfirm, setMakeJobAlertConfirm] = useState("");

  const CompanyJobAlertCheck = async () => {
    try {
      const result = await axios.post(`Company/CheckCompJobAlert`, {
        deviceType: "Web",
        compUid: CompUid,
        sessionId,
      });
      const Response = result.data.message;
      setMakeJobAlertConfirm(Response);
      setJobAlert(Response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    CompanyJobAlertCheck();
  }, [CompUid]);


  //   Mark job alert
  const [markAlert, setMarkAlert] = useState("");
  const markJobAlert = async () => {
    try {
      const response = {
        deviceType: "Web",
        sessionId,
        compUid: CompUid,
      };
      const result = await axios.post(`Company/AddCompJobAlert`, response);
      setMarkAlert(result.data.message);
      setToastMessage(result.data.message);
      setShowToast(true);
      CompanyJobAlertCheck();
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //   Unmark job alert
  const unMarkJobAlert = async () => {
    try {
      const response = {
        deviceType: "Web",
        sessionId,
        compUid: CompUid,
      };
      const result = await axios.post(`Company/DeleteCompJobAlert`, response);
      setToastMessage(result.data.message);
      setShowToast(true);
      CompanyJobAlertCheck();
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Follow Company  ************************//

  const FollowCompany = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      uiD_ID: CompUid,
    };
    try {
      await axios.post("Entity/Follow/Company", data).then((resData) => {
        CompanyDetails();
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ UnFollow Company  ************************//

  const UnFollowCompany = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      uiD_ID: CompUid,
    };
    try {
      await axios.post("Entity/Unfollow/Company", data).then((resData) => {
        CompanyDetails();
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Company tats Count ************************//

  const [companyStats, setCompanyStats] = useState("");
  const CompanyStatsCount = async () => {
    try {
      const response = {
        deviceType: "Web",
        compUid: CompUid,
      };
      const result = await axios.post(`Company/GetCompanyStats`, response);
      const Response = result.data.responseData;
      setCompanyStats(Response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    CompanyStatsCount();
  }, [CompUid]);

  //************************ Add review ************************//

  const AddReview = async () => {
    const reviewData = {
      deviceType: "Web",
      sessionId: sessionId,
      compUid: CompUid,
      review: reviewContent,
      rating: 0,
    };
    try {
      await axios.post("Company/AddCompanyReview", reviewData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        setShowReviewModal(false);
        setReviewContent();
        setShowDropdown(false);
        CompanyDetails()
        CompanyStatsCount();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //   State and function for Bg
  const [openBgModal, setOpenBgModal] = useState(false);
  const handleOpen = () => {
    setOpenBgModal(true);
  };
  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleOpenBgCloseModal = () => {
    setOpenBgModal(false);
  };

  //   State and function for profile
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const handleOpenProfileCloseModal = () => {
    setOpenProfileModal(false);
  };

  //   For to overflow scoll handle
  useEffect(() => {
    if (showReviewModal || openBgModal || openProfileModal || jobModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showReviewModal, openBgModal, openProfileModal, jobModal]);

  //   State for Dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  //   For Unfollow user
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleUnfollow = () => {
    if (guestModeData === "true") {
      setJobModal(true);
    } else {
      UnFollowCompany();
    }
    setShowDropdown(false);
  };

  //   For Unmark job alert
  const handleUnmarkJobAlert = () => {
    if (guestModeData === "true") {
      setJobModal(true);
    } else {
      unMarkJobAlert();
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    handleScroll();
  }, []);



  //************************ Background Image   ************************//
  const [bgSelectedImage, setBgSelectedImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  // console.log(backgroundImage);
  const [bgModal, setBgModal] = useState(false);
  const handleBgModal = () => {
    setBgModal(false);
  };
  const fileInputRefBg = useRef(null);
  const handleBgClick = () => {
    fileInputRefBg.current.click();
  };
  const handleBgImageChange = (event) => {
    const selectedFile = event.target.files[0];
    // setBackgroundImage(event.target.files[0].name);
    setBackgroundImage(selectedFile);
    setBgSelectedImage(URL.createObjectURL(selectedFile));
    setBgModal(true)
  };

  const BgImageUpload = async () => {
    try {
      if (backgroundImage !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("BackGroundImage", backgroundImage);
        formData.append("CompUid", CompUid);
        const res = await axios.post(
          "/Company/SetCompanyBackgroundImage",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setBgModal(false);
        CompanyDetails();
        // getUserProfile()
        // userConfig();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Background Image End  ************************//

  //************************ Profile Picture   ************************//
  const [selectedprofileImage, setselectedprofileImage] = useState(null);
  const [profileImage, setprofileImage] = useState(null);
  // console.log(profileImage);
  const [profileModal, setprofileModal] = useState(false);
  const handleprofileModal = () => {
    setprofileModal(false);
  };
  const fileInputRefprofile = useRef(null);
  const handleProfileClick = () => {
    fileInputRefprofile.current.click();
  };
  const handleProfileImageChange = (event) => {
    const selectedFile = event.target.files[0];

    setprofileImage(selectedFile);
    // setprofileImage(event.target.files[0].name);
    setselectedprofileImage(URL.createObjectURL(selectedFile));
    setprofileModal(true)
  };

  const ProfileImageUpload = async () => {
    try {
      if (profileImage !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("CompanyLogo", profileImage);
        formData.append("CompUid", CompUid);
        const res = await axios.post("/Company/SetCompanyLogo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setprofileModal(false);
        // getUserProfile()
        CompanyDetails()
        // userConfig();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Profile Picture End  ************************//
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  console.log('companyDetails', companyDetails);


  return (
    <>
      {companyError ? <div className="mt-2 text-center">
        <img
          src={`https://welinkjobs.com/Images/Default%20Images/404/PNG/No%20Result%202.png`}
          alt="404 Not Found"
          width={'500px'}
        />
      </div> : (
        <>
          {isLoading ? (
            <>
              <ShimmerDiv mode="light" height={100} width={'100%'} />
            </>
          ) : (
            <div className="card profileCard desktopView mt-3" style={{ zIndex: showUpdateModal ? 2 : 1 }}>
              <div className="Profilebackground">
                <div
                  className="background-overlay"
                  onClick={() => handleOpen()}
                >
                  <img src={companyDetails?.backGroundImage} alt="Background" />
                  {companyDetails?.isOwned && (
                    <>
                      {/* Replace label with a div to avoid automatic click handling */}
                      <div
                        className="backgroundimg-edit"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the parent onClick from firing
                          handleBgClick(); // Trigger the file input click
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <EditSharpIcon className="material-symbols-sharp" />
                      </div>
                    </>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRefBg}
                  style={{ display: "none" }}
                  onChange={handleBgImageChange}
                  accept="image/*"
                />
              </div>
              <div
                className="profileImg"
                onClick={() => setOpenProfileModal(true)}
              >
                <img
                  src={companyDetails?.logoImage}
                  alt=""
                  srcset=""
                  style={{ borderRadius: "50px" }}
                />
                {companyDetails?.isOwned && (
                  <>
                    <label
                      htmlFor="fileInput"
                      className="background-edit"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleProfileClick()
                      }}
                    >
                      <EditSharpIcon className="material-symbols-sharp" />

                    </label>
                  </>
                )}
                <input
                  type="file"
                  ref={fileInputRefprofile}
                  style={{ display: "none" }}
                  onChange={handleProfileImageChange}
                  accept="image/*"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
              </div>

              <div className="companyPage profileDetails pointer" onClick={(event) => setOpenBgModal(true)}>
                <h4 onClick={(e) => e.stopPropagation()}>
                  {companyDetails?.compName}
                </h4>
                <p onClick={(e) => e.stopPropagation()}>{companyDetails?.industryName}</p>
                <p onClick={(e) => e.stopPropagation()}>{companyDetails?.companyCategoryName}</p>
                <div className="d-flex align-items-center" onClick={(e) => e.stopPropagation()}>
                  <div className="  badge-vip ms-0 me-2">
                    {companyDetails?.compUid}{" "}
                  </div>
                  {companyDetails?.isOwned &&
                    <CompanyActions showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} compUid={companyDetails?.compUid} getCompanyList={CompanyDetails} item={companyDetails} />
                  }
                </div>
              </div>

            </div>
          )}
          {isLoading ? (
            <>
              <ShimmerDiv mode="light" height={100} width={'100%'} />
            </>
          ) : (
            <div className="card profileCard mobileView" style={{ zIndex: showUpdateModal ? 2 : 1 }}>

              <div className="Profilebackground">
                <div
                  className="background-overlay"
                  onClick={() => handleOpen()}
                >
                  <img src={companyDetails?.backGroundImage} alt="Background" />
                  {companyDetails?.isOwned && (
                    <>
                      {/* Replace label with a div to avoid automatic click handling */}
                      <div
                        className="backgroundimg-edit"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the parent onClick from firing
                          handleBgClick(); // Trigger the file input click
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <EditSharpIcon className="material-symbols-sharp" />
                      </div>
                    </>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRefBg}
                  style={{ display: "none" }}
                  onChange={handleBgImageChange}
                  accept="image/*"
                />
              </div>
              <div
                className="profileImg"
                onClick={() => setOpenProfileModal(true)}
              >
                <img
                  src={companyDetails?.logoImage}
                  alt=""
                  srcset=""
                  style={{ borderRadius: "50px" }}
                />
                {companyDetails?.isOwned && (
                  <>
                    <label
                      htmlFor="fileInput"
                      className="background-edit"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleProfileClick()
                      }}
                    >
                      <EditSharpIcon className="material-symbols-sharp" />
                    </label>
                  </>
                )}
                <input
                  type="file"
                  ref={fileInputRefprofile}
                  style={{ display: "none" }}
                  onChange={handleProfileImageChange}
                  accept="image/*"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
              </div>

              <div className="companyPage pointer profileDetails-mb pt-4  p-2 text-center" onClick={(event) => setOpenBgModal(true)}>
                <h4>
                  {companyDetails?.compName}
                </h4>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="badge badge-vip ms-0 me-2">
                    {companyDetails?.compUid}{" "}
                    <InfoSharpIcon className="material-symbols-sharp ms-3" />
                  </div>
                  {companyDetails?.isOwned &&
                    <CompanyActions showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} compUid={companyDetails?.compUid} getCompanyList={CompanyDetails} item={companyDetails} />
                  }
                </div>
              </div>
            </div>
          )}
          <div className="business-main-container position-relative mt-3">

            <div className="sub-content-container">
              <Row>
                <Col md={4} xs={12} className="mobile-view" style={{ paddingRight: '24px' }}>
                  <div>
                    {/* ************************  For desktopView ************************ */}
                    {isLoading ? (
                      <>
                        <ShimmerDiv mode="light" height={200} width={'100%'} />
                      </>
                    ) : (
                      <div className="followerTabs">
                        <div className="tabButton d-flex justify-content-around w-100 me-2 pt-2">
                          <NavLink
                            onClick={() => setActiveTab(1)}
                            className={`text-center ${activeTab === 1 ? "activeTab" : ""
                              }`}
                          >
                            <p className="f-12 tabCountFollower">
                              {companyStats?.totaPosts}
                            </p>
                            <p className="f-10 tabName">Posts</p>
                          </NavLink>
                          <NavLink
                            onClick={() => setActiveTab(2)}
                            className={`text-center ${activeTab === 2 ? "activeTab" : ""
                              }`}
                          >
                            <p className="f-12 tabCountFollower">
                              {companyStats?.totalJobs}
                            </p>
                            <p className="f-10 tabName">Jobs</p>
                          </NavLink>
                          <NavLink
                            onClick={() => setActiveTab(3)}
                            className={`text-center ${activeTab === 3 ? "activeTab" : ""
                              }`}
                          >
                            <p className="f-12  tabCountFollower">
                              {companyStats?.totalFollowers}
                            </p>
                            <p className="f-10 tabName">Followers</p>
                          </NavLink>
                          <NavLink
                            onClick={() => setActiveTab(4)}
                            className={`text-center ${activeTab === 4 ? "activeTab" : ""
                              }`}
                          >
                            <p className="f-12  tabCountFollower">
                              {companyStats?.totaPostsReviews}
                            </p>
                            <p className="f-10 tabName">Reviews</p>
                          </NavLink>
                        </div>
                      </div>
                    )}
                    {companyDetails?.isOwned === false && (
                      <div className="businessCardButton d-flex align-items-center p-2 ps-0 pe-0">
                        <>
                          {companyDetails?.isFollowedByYou === true ? <button
                            type="submit"
                            className="wljSecondaryBtn me-2"
                            style={{ width: '90px', height: '40px' }}
                            onClick={() => {
                              handleUnfollow()
                            }}
                          >
                            Unfollow
                          </button> : <button
                            className="mainBtn1 me-2"
                            style={{ width: "90px" }}
                            onClick={() =>
                              guestModeData === "true"
                                ? setJobModal(true)
                                : FollowCompany()
                            }
                          >
                            Follow
                          </button>}



                          {jobAlert === 'Company job alert found!' ? <button
                            type="submit"
                            className="wljSecondaryBtn"
                            style={{ width: '110px', height: '40px', fontSize: '11px' }}
                            onClick={() => {
                              handleUnmarkJobAlert()
                            }}
                          >
                            Unmark Job Alert
                          </button> : <button
                            className="mainBtn1 me-1 markBtn"
                            style={{ width: "90px" }}
                            onClick={() =>
                              guestModeData === "true"
                                ? setJobModal(true)
                                : markJobAlert()
                            }
                          >
                            Mark Job Alert
                          </button>}
                        </>
                      </div>
                    )}

                    {/* ************************  For Mobile ************************ */}


                  </div>

                  {isLoading ? (
                    <>
                      <ShimmerDiv mode="light" height={200} width={'100%'} />
                    </>
                  ) : (
                    <>
                      <AboutCompany
                        CompUid={CompUid}
                        companyDetails={companyDetails}
                      />
                    </>
                  )}
                </Col>

                <Col md={8} xs={12} className="userProductContainer">
                  {isLoading ? (
                    <>
                      <ShimmerDiv mode="light" height={200} width={'100%'} />
                    </>
                  ) : (
                    <>
                      <div>{renderTabContent()}</div>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4} className="p-0"></Col>
              </Row>
            </div>
          </div></>)}

      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Unfollow User</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </div>

              <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                <h2>Hello Follow</h2>
              </div>
            </div>
          </div>
        </div>
      )}

      {showReviewModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Add Review</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseReviewModal();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseReviewModal();
                  }}
                />
              </div>

              <div className="col-md-12 mt-2 ">
                <textarea
                  type="text"
                  style={{ width: "100%", height: "100px" }}
                  value={reviewContent}
                  onChange={(e) => setReviewContent(e.target.value)}
                  className="textReview"
                ></textarea>
              </div>
              <div className="col-md-12 text-end">
                <button type="button" className="mainBtn1" onClick={AddReview}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {openBgModal && (
        <div
          className="modal fade show postAdd modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
          size="lg"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Company Background View</h6>
                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => handleOpenBgCloseModal()}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => handleOpenBgCloseModal()}
                />
              </div>
              <div className="p-2">
                <div className="backrgound-profile-view d-flex justify-content-center">
                  <img src={companyDetails?.backGroundImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {openProfileModal && (
        <div
          className="modal fade show postAdd modal-sm"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Company Logo</h6>
                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={handleOpenProfileCloseModal}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={handleOpenProfileCloseModal}
                />
              </div>
              <div className="p-2">
                <div className="profile-view">
                  <img src={companyDetails?.logoImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {jobModal && <ModalForGuest closeModalData={closeModalData} />}

      {bgModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Background Image</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleBgModal();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleBgModal();
                  }}
                />
              </div>

              <div className="uploaded-imagebg mt-2">
                {bgSelectedImage && (
                  <img src={bgSelectedImage} alt="Selected" height={200} />
                )}
              </div>

              <div className="col-md-12 text-end mt-4">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={BgImageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {profileModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Company Profile Pic</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleprofileModal();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleprofileModal();
                  }}
                />
              </div>
              <div className="d-flex">

              </div>
              <div className="uploaded-imagebg mt-2">
                {selectedprofileImage && (
                  <img src={selectedprofileImage} alt="Selected" height={200} />
                )}
              </div>

              <div className="col-md-12 text-end mt-4">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={ProfileImageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessPage;
