import React, { useEffect, useState } from "react";
import "../../assets/css/Post.css";
import RightNavigation from "../../Views/Navigation/RightNavigation";
// import { NewsFeed, post } from "../../assets/Images/ImagesLink";
import PostInteractionModal from "../NewsFeed/PostInteractionModal";
import axios from "../../API/axios";
import { Link, NavLink, useParams } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";
import moment from "moment/moment";
import PostEngagement from "../NewsFeed/PostEngagement";
import ImageSliderModal from "../NewsFeed/ImageSliderModal";
import { ShimmerDiv } from "shimmer-effects-react";
import SinglePostEngagement from "../NewsFeed/SinglePostEngagement";
import { header, post } from "../../assets/Images/ImagesLink";
import Slider from "react-slick";
import SinglePostInteraction from "../NewsFeed/SinglePostInteraction";
import SinglePostComment from "../NewsFeed/SinglePostComment";
const Post = () => {
  const guestModeData = localStorage.getItem("guestMode");
  const sessionId = localStorage.getItem("sessionId");
  const { PostId } = useParams();
  const [postList, setPostList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isError, setIsError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [dLike, setDLike] = useState(0);
  const [singlepostId, setSinglePostId] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  const openModal = (images) => {
    // console.log('clicke');
    setModalImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [singlePostContent, setSinglePostContent] = useState('')
  const [ispostmodalOpen, setIspostmodalOpen] = useState(false)


  const updateLikeCount = (postId, delta) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId ? { ...post, likeCount: post.likeCount + delta } : post
      )
    );
  };

  const updateCommentCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, commentCount: post.commentCount + change }
          : post
      )
    );
  };
  const updateShareCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, shareCount: post.shareCount + change }
          : post
      )
    );
  };
  const [postLikedType, setPostLikedType] = useState(false);
  const handleShowModal = (item) => {
    // setPostId(item.postId);
    setPostLikedType(item.likedType);
    setShowModal(true);
  };
  const [singlepostList, setSinglePostList] = useState([])
  console.log('singlepostList', singlepostList);
  const AllPostList = async () => {
    const Data = {
      "deviceType": "Web",
      "sessionId": sessionId
    }
    try {
      await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${PostId}`).then((resData) => {
        setIsLoading(false);
        console.log('resData', resData);
        if (resData.status === 204) {
          setIsError(true)
        } else {

          setSinglePostList(resData.data.responseData)
        }
      });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    AllPostList()
  }, []);

  const addTargetBlankToAnchors = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;

    const anchors = div.querySelectorAll('a');
    anchors.forEach(anchor => {
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('rel', 'noopener noreferrer');
    });

    return div.innerHTML;
  };

  const linkifyContent = (htmlContent) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return htmlContent.replace(urlPattern, function (url) {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  return (
    <>

      {isError ? (
        <div className="mt-2 d-flex justify-content-center align-items-center">
          <img src={post.errorSmall} alt="404 Not Found" width={200} />
        </div>
      ) : <div className="row w-100 m-0 singlePostLink">
        <div
          className={` ${singlepostList?.mediaFilesList?.length === 0
            ? "d-none"
            : "col-md-8"
            } p-0`}
        >
          <div className="image-slider-modal text-center">
            {singlepostList?.length > 1 ? (
              <Slider>
                {singlepostList?.mediaFilesList?.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image.mediaName}
                      alt={`Slide ${index}`}
                      className="img-fluid m-auto modal-image"
                    // onLoad={handleImageLoad}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <img
                src={singlepostList?.mediaFilesList?.[0]?.mediaName}
                // alt="Single Image"
                className="img-fluid m-auto modal-image"
              // onLoad={handleImageLoad} // Call handleImageLoad when image loads
              />
            )}
          </div>
        </div>
        <div
          className={` ${singlepostList?.mediaFilesList?.length === 0
            ? "col-md-12"
            : "col-md-4"
            } p-0`}
          style={{ overflowY: "auto", maxHeight: "90vh" }}
        >
          <div className="featured-card user-postOnlyContent p-2 m-2">
            <div className="feature-content p-2 d-flex justify-content-between">
              {singlepostList?.postOriginType === "Company" ? <Link to={`/Company/${singlepostList?.userUid}`}>
                <div className="d-flex align-items-center">
                  <div className="ImgAvatar">
                    <img
                      src={singlepostList?.userAvatar}
                      alt=""
                      style={{ borderRadius: "50px" }}
                    />
                  </div>
                  <div className="ms-2">
                    <h4 className="f-14 fw-bold mb-0">
                      {singlepostList?.userName}
                    </h4>
                    <p className="f-12">
                      {/* {moment(singlepostList.creationTime).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )} */}
                      {moment
                        .utc(singlepostList?.creationTime)
                        .local()
                        .format("DD MMMM YYYY [at] h:mm a")}
                    </p>
                  </div>
                </div>
              </Link> : <Link to={`/User/${singlepostList?.userUid}`}>
                <div className="d-flex align-items-center">
                  <div className="ImgAvatar">
                    <img
                      src={singlepostList?.userAvatar}
                      alt=""
                      style={{ borderRadius: "50px" }}
                    />
                  </div>
                  <div className="ms-2">
                    <h4 className="f-14 fw-bold mb-0">
                      {singlepostList?.userName}
                    </h4>
                    <p className="f-12">
                      {/* {moment(singlepostList.creationTime).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )} */}
                      {moment
                        .utc(singlepostList?.creationTime)
                        .local()
                        .format("DD MMMM YYYY [at] h:mm a")}
                    </p>
                  </div>
                </div>
              </Link>}


              {guestModeData === "true" ? null : (
                <div className="row">
                  <div>
                    <SinglePostInteraction
                      postId={singlepostList?.postId}
                      AllPostList={AllPostList}
                      singlepostList={singlepostList}
                      setSinglePostList={setSinglePostList}
                    // isfollowed={isfollowed}
                    // setIsfollowed={setIsfollowed}
                    />
                  </div>
                </div>
              )}
            </div>
            {singlepostList?.postContent && (
              <div className="f-14 p-2 postContent">
                <div
                  dangerouslySetInnerHTML={{
                    __html: linkifyContent(
                      addTargetBlankToAnchors(singlepostList?.postContent)
                    )
                  }}
                />
              </div>
            )}
            {/* <p className="f-14 p-2">{singlepostList?.postContent}</p> */}
            <div className="feed-post p-2">
              {guestModeData === "true" ? null : (
                <div className="row">
                  <div className="addComment">
                    <SinglePostComment
                      postId={singlepostList?.postId}
                      AllPostList={AllPostList}
                      singlepostList={singlepostList}
                      setSinglePostList={setSinglePostList}
                      ispostmodalOpen={ispostmodalOpen}
                      setIspostmodalOpen={setIspostmodalOpen}
                      setPostList={setPostList}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={`postData d-flex align-items-center ${singlepostList?.likeCount === 0 && singlepostList?.commentCount === 0 && singlepostList?.shareCount === 0
              ? ""
              : "border-custom1"
              }`}>
              <div className="ms-2">
                {singlepostList?.likeCount + dLike === 0 ? (
                  ""
                ) : (
                  <p
                    className="f-10 like-details"
                    onClick={() => handleShowModal(singlepostList)}
                  >
                    {singlepostList?.likeCount + dLike} likes
                  </p>
                )}
              </div>
              <div className="ms-auto d-flex">
                {singlepostList?.commentCount === 0 ? (
                  ""
                ) : (
                  <p className="f-10 me-2 pointer">
                    {singlepostList?.commentCount} comments
                  </p>
                )}

                <p className="f-10">
                  {singlepostList?.shareCount === 0
                    ? ""
                    : `&  ${singlepostList?.shareCount} Shares`}
                </p>
              </div>
            </div>
            <div className="postengagement">
              <SinglePostEngagement
                postId={singlepostList?.postId}
                AllPostList={AllPostList}
                singlepostList={singlepostList}
                setSinglePostList={setSinglePostList}
                ispostmodalOpen={ispostmodalOpen}
                setIspostmodalOpen={setIspostmodalOpen}
                openModal={openModal}
                setSinglePostId={setSinglePostId}
                setSinglePostContent={setSinglePostContent}
                setDLike={setDLike}
                updateLikeCount={updateLikeCount}
              // setPostList={setPostList}
              />
            </div>
          </div>
        </div>
      </div>}



    </>
  );
};

export default Post;
