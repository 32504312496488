import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/navigation.css";
import { people, Profile } from "../../assets/Images/ImagesLink";
import { IoIosArrowDown } from "react-icons/io";
import CreateCompany from "../CreateCompany/CreateCompany";
import { GetProfileInfo } from "../../Services/Service";
import axios, { IMAGE_URL } from "../../API/axios";
import ViewAll from "./ViewAll";
import Blogs from "../Blogs/Blogs";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
// import work from "../assets/Images/svg/icon_work";
import work from "../../assets/Images/svg/icon_work.svg";
import PersonalProfile from "../PersonalProfile/PersonalProfile";
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import HubSharpIcon from '@mui/icons-material/HubSharp';
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import AddBusinessSharpIcon from '@mui/icons-material/AddBusinessSharp';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import WorkHistorySharpIcon from '@mui/icons-material/WorkHistorySharp';
import FeedSharpIcon from '@mui/icons-material/FeedSharp';
import RssFeedSharpIcon from '@mui/icons-material/RssFeedSharp';
import ContactSupportSharpIcon from '@mui/icons-material/ContactSupportSharp';
import SettingsAccessibilitySharpIcon from '@mui/icons-material/SettingsAccessibilitySharp';
import GavelSharpIcon from '@mui/icons-material/GavelSharp';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
import CopyrightSharpIcon from '@mui/icons-material/CopyrightSharp';
import PersonSearchSharpIcon from '@mui/icons-material/PersonSearchSharp';
import PrivacyTipSharpIcon from '@mui/icons-material/PrivacyTipSharp';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import AdminPanelSettingsSharpIcon from '@mui/icons-material/AdminPanelSettingsSharp';
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";
const LeftNavigation = ({
  isOpen,
  toggleMenu,
  data,
  setIsOpen,
  openModal,
  showModal,
  closeModal,
}) => {
  const UpdateLeftNavigationUserData = () => { };

  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");
  // const profilPic = localStorage.getItem("profilPic");
  const userUid = localStorage.getItem("userUid");
  const usernameTag = localStorage.getItem("user-nameTag");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  const navigate = useNavigate();
  // const [profileData, setProfileData] = useState();
  const [ownCompanyList, setOwnCompanyList] = useState([]);


  const [profilPic, setProfilPic] = useState(localStorage.getItem("profilPic"));

  useEffect(() => {
    const handleProfilePicUpdate = (event) => {
      setProfilPic(event.detail);
    };

    window.addEventListener('profilePicUpdated', handleProfilePicUpdate);

    return () => {
      window.removeEventListener('profilePicUpdated', handleProfilePicUpdate);
    };
  }, []);


  //************************ Get Own Companies List  ************************//
  const [loading, setLoading] = useState(true);
  const GetOwnCompniesListsData = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      const result = await axios.post(`Company/GetOwnCompaniesList`, {
        sessionId,
        deviceType: "Web",
      });
      const Response = result.data.responseData;
      // console.log('GetOwnCompniesListsData', Response);
      setOwnCompanyList(Response);
      localStorage.setItem('ownCompanyList', Response.length)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetOwnCompniesListsData();
  }, [sessionId]);

  // console.log("guestModeData===>", data);

  //************************  Get Own Companies List End  ************************//
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Sign In required
    </Tooltip>
  );
  // console.log('profileData', profileData);

  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === "/NewsFeed") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };


  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [profileConfig, setProfileConfig] = useState([]);
  const userConfig = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        // console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  const [userCcv, setUserCcv] = useState("");
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .get(`Ccv/CheckUserCcv?DeviceType=Web&UserUid=${userUid}`)
        .then((resData) => {
          // console.log('resData', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      console.log(error.response);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };

  useEffect(() => {
    userConfig();
    CheckUserCcv();
  }, []);
  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Ccv/AddUserCcv`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const [showResumeModal, setShowResumeModal] = useState(false);
  const onEvent = () => {
    (profileConfig.hasCcv === null || profileConfig.hasCcv === false) &&
      userPer?.percentage < 60
      ? setShowResumeModal(!showResumeModal)
      : openModal()
    toggleMenu()

  };
  return (
    <>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <div className="sideLinks leftNav">
          <div className="text-end ToggleClose" onClick={toggleMenu}>
            <CloseSharpIcon className="material-symbols-sharp" />

          </div>
          {guestModeData === "true" ? (
            <NavLink to="/SignIn" onClick={toggleMenu}>
              <div className="personal d-flex align-items-center px-3 py-2">
                <img
                  src={people.DefaultProfile}
                  width={40}
                  height={40}
                  alt=""
                  style={{ borderRadius: "50px" }}
                />
                <div className="ms-2">
                  <h5 className="f-12 mb-0 fw-bold">
                    <div>Guest Mode</div>

                    <p className="f-10 mt-1 sign-up-text">Sign Up Now</p>
                  </h5>
                </div>
              </div>
            </NavLink>
          ) : (
            <div className="personal d-flex align-items-center px-3 py-2">
              <Link
                to={`/User/${userUid}`}
                className="d-flex align-items-center"
                onClick={toggleMenu}
              >
                <img
                  src={`${profilPic}`}
                  width={40}
                  height={40}
                  alt=""
                  style={{ borderRadius: "50px" }}
                />

                <div className="ms-2">
                  <h5 className="f-12 mb-0 fw-bold">
                    {firstName} {lastName}
                    {/* {lastName.length > 16 ? `${lastName.substring(0, 16)}...` : lastName} */}
                  </h5>
                  {/* <span className="f-12">{usernameTag}</span> */}
                  <span className="f-12">
                    {usernameTag?.length > 16 ? `${usernameTag?.substring(0, 16)}...` : usernameTag}
                  </span>

                </div>
              </Link>
              {/* <div className="dropdown ms-auto">
                <IoIosArrowDown />
              </div> */}
            </div>
          )}

          <ul className="ps-0 listLink">
            <NavLink
              to="/NewsFeed"
              onClick={() => {
                toggleMenu();
                handleLogoClick();
              }}
            >
              <li className="navLinks">
                <HomeSharpIcon />
                <span>News Feeds</span>
              </li>
            </NavLink>
            {guestModeData === "true" ? (
              ""
            ) : (
              <NavLink to="/Draft" onClick={toggleMenu}>
                <li className="navLinks">
                  <EditNoteSharpIcon />
                  <span>Drafts</span>
                </li>
              </NavLink>
            )}

            {guestModeData === "true" ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <li className="navLinks">
                  <HubSharpIcon className="GuestMode" />
                  <span className="GuestMode">My Connections</span>
                </li>
              </OverlayTrigger>
            ) : (
              <NavLink to="/MyConnections" onClick={toggleMenu}>
                <li className="navLinks">
                  <HubSharpIcon />
                  <span>My Connections</span>
                </li>
              </NavLink>
            )}
            <NavLink to="/People" onClick={toggleMenu}>
              <li className="navLinks">
                <PersonSearchSharpIcon />
                <span>People</span>
              </li>
            </NavLink>

            {guestModeData === "true" ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <li className="navLinks">

                  <WorkHistorySharpIcon className="GuestMode" />
                  <span className="GuestMode">Manage Job</span>
                </li>
              </OverlayTrigger>
            ) : (
              <NavLink to="/ManageJobs" onClick={toggleMenu}>
                <li className="navLinks">
                  <WorkHistorySharpIcon />
                  <span>Manage Job</span>
                </li>
              </NavLink>
            )}
            <NavLink to="/Companies" onClick={toggleMenu}>
              <li className="navLinks">
                <BusinessSharpIcon />
                <span>Companies</span>
              </li>
            </NavLink>

            {guestModeData === "true" ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <li className="navLinks">

                  <AddBusinessSharpIcon className="GuestMode" />
                  <span className="GuestMode">Create Company</span>
                </li>
              </OverlayTrigger>
            ) : (
              <>
                {loading ? (
                  ""
                ) : ownCompanyList && ownCompanyList.length >= 1 ? (
                  ""
                ) : (
                  <li className="navLinks pointer" onClick={onEvent}>
                    <AddBusinessSharpIcon />
                    <span>Create Company</span>
                  </li>
                )}
              </>
            )}
            {guestModeData === "true" ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <li className="navLinks">

                  <NotificationsSharpIcon className="GuestMode" />
                  <span className="GuestMode">Job Applications</span>
                </li>
              </OverlayTrigger>
            ) : (
              ownCompanyList && ownCompanyList.length >= 1 ?
                <NavLink to="/JobApplications" onClick={toggleMenu}>
                  <li className="navLinks">
                    <NotificationsSharpIcon />
                    <span>Job Applications</span>
                  </li>
                </NavLink> : ''
            )}


            <NavLink to="/SearchJob" onClick={toggleMenu}>
              <li className="navLinks">

                {/* <img src={work} /> */}
                <WorkSharpIcon />
                <span>Search Job</span>
              </li>
            </NavLink>

          </ul>

          {guestModeData === "true" ? (
            ""
          ) : ownCompanyList && ownCompanyList.length > 0 ? (
            <div className="hashtags p-0">
              <div className=" d-flex  align-items-center">
                <h4 className="heading mb-0 me-2">My Companies
                </h4>
                <NavLink
                  className="f-12 d-flex justify-content-center"
                  to={`/MyCompanies`}
                  onClick={toggleMenu}
                >
                  (Manage)
                </NavLink>
                {/* <NavLink to='/'>
                                View All
                            </NavLink> */}
              </div>
              <ul className="ps-0 listLink">
                {ownCompanyList &&
                  ownCompanyList?.slice(0, 3)?.map((item, index) => {
                    // console.log('own company', item);
                    return (
                      <NavLink to={`/Company/${item.compUid}`} key={index} onClick={toggleMenu}>
                        <li className="navLinks d-flex align-items-center mt-3">

                          <img
                            src={item?.logoImage}
                            alt=""
                            width="25"
                            height="25"
                            style={{ borderRadius: "50px" }}
                          />
                          <span> {item.compName}</span>
                        </li>
                      </NavLink>
                    );
                  })}

              </ul>
            </div>
          ) : (
            ""
          )}

          <ul className="ps-0 pb-5 listLink">
            <NavLink to="/Blogs" target="blank">
              <li className="navLinks">
                <FeedSharpIcon />
                <span>Blogs</span>
              </li>
            </NavLink>

            <NavLink to="/aboutus.html" target="blank">
              <li className="navLinks">
                <RssFeedSharpIcon />
                <span>About</span>
              </li>
            </NavLink>
            <NavLink to="/faq.html" target="blank">
              <li className="navLinks">
                <ContactSupportSharpIcon />
                <span>Faqs</span>
              </li>
            </NavLink>
            <NavLink to="/user-agreement.html" target="blank">
              <li className="navLinks">

                <SettingsAccessibilitySharpIcon />
                <span>User Agreement</span>
              </li>
            </NavLink>

            <NavLink to="/privacy-policy.html" target="blank">
              <li className="navLinks">
                <AdminPanelSettingsSharpIcon />
                <span>Privacy Policy</span>
              </li>
            </NavLink>

            <NavLink to="/terms-and-conditions.html" target="blank">
              <li className="navLinks">
                <GavelSharpIcon />
                <span>Terms & Conditions</span>
              </li>
            </NavLink>

            <NavLink to="/downlaodapp.html" target="blank">
              <li className="navLinks">
                <LaunchSharpIcon />
                <span>Get the Welinkjobs App</span>
              </li>
            </NavLink>
            {/* <NavLink to="/"> */}
            <li className="navLinks">
              <CopyrightSharpIcon />
              <span>Welinkjobs 2024</span>
            </li>
          </ul>
        </div>
      </div>



      {showResumeModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Please complete your profile up to 60% and must have valid resume to Create Company</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShowResumeModal(false);
                  }}
                />
              </div>
              <div className="text-center mt-2">
                <Link to={`/User/${userUid}`}>
                  <button
                    color=""
                    className="closebtn"
                    style={{ width: "150px" }}
                    // onClick={() => {
                    //   userCcv !== "True" && AddUserCcv();
                    // }}
                    onClick={() => {
                      setShowResumeModal(false);
                    }}
                  >
                    Manage Profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default LeftNavigation;